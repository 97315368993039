import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Trans } from 'react-i18next'
import { MODAL_REWARD } from '@Modals/index'
import { toggleModal } from '@Store/template/actions'
import ModalReward from '@Modals/ModalReward'

const sampleUsers = [
	'Carlo Martini',
	'Luca Mastrostefano',
	'Antonio Romano',
	'Valentina Ferretti',
	'Gerta Salillari',
	'Giada Loi',
]

const BoxInfoFaculty = props => {

	const dispatch = useDispatch()

	const listParticipants = useSelector(state => state.FormVote.listParticipants)
	const dataCheckVote = useSelector(state => state.FormVote.dataCheckVote)

	const faculty = dataCheckVote?.faculty

	// Number of available places are always lower than 20
	const totalPlaces = Math.floor(listParticipants.faculty?.length / 20 + 1) * 20
	const availablePlaces = totalPlaces - listParticipants.faculty?.length

	const renderBeforeSelectFaculty = () => (
		<div className="container-students">
			<div className="list-students blurred">
				<div className="blurred-box">
					<Trans i18nKey="stepUniversity.boxInfoFaculty.emptyTitle"/>
				</div>
				{sampleUsers.map((u, k) => (
					<div className="student blurred" key={k}>{u}</div>
				))}
			</div>
		</div>
	)

	const renderWithListParticipants = () => (
		<div className="container-available-votes" onClick={() => dispatch(toggleModal(MODAL_REWARD, true))}>
			<div className="number">
				<span className="icon-flame d-none d-md-inline-block">🔥</span>
				<Trans i18nKey="stepUniversity.boxInfoFaculty.openPositions" values={{ availablePlaces, totalPlaces }}/>
				<span className="icon-flame d-none d-md-inline-block">🔥</span>
			</div>
			<div className="rounded-bar"/>
			<div className="text"><Trans i18nKey="stepUniversity.boxInfoFaculty.availableVotes"/></div>
			<ModalReward />
		</div>
	)

	const renderWithoutListParticipants = () => (
		<div className="container-available-votes" onClick={() => dispatch(toggleModal(MODAL_REWARD, true))}>
			<div className="number">
				<strong className="d-none d-md-block">
					<span className="icon-flame d-none d-md-inline-block">🔥</span>
					<span className="text-padded">20</span>
					<span className="icon-flame d-none d-md-inline-block">🔥</span>
					<div className="rounded-bar"/>
				</strong>
				<strong className="d-md-none">20</strong>
			</div>
			<div className="text"><Trans i18nKey="stepUniversity.boxInfoFaculty.availableVotesInFaculty"/></div>
			<ModalReward />
		</div>
	)

	const render = () => {
		if (!faculty) return renderBeforeSelectFaculty()
		if (listParticipants.faculty && listParticipants.faculty.length > 0) return renderWithListParticipants()
		return renderWithoutListParticipants()
	}

	return render()
}

export default BoxInfoFaculty
