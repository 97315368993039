import {ReactComponent as AmazonIcon} from '@Assets/images/amazon.svg'
import PropTypes from 'prop-types'

const Amazon = props => {

	return (
		<AmazonIcon
			className={props.className}
			style={props.style}
			alt="" />
	)
}

Amazon.propTypes = {
	style: PropTypes.object,
	className: PropTypes.string,
}

export default Amazon
