import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import ReactCardFlip from 'react-card-flip'
import arrow from '@Assets/images/arrow.svg'

const BoxArrow = props => {

	const { i18n } = useTranslation()

	const [meme1, setMeme1] = useState()
	const [meme2, setMeme2] = useState()

	import(`@Assets/images/stepWelcome/${i18n.languages[0]}/meme1.png`).then((img) => setMeme1(img.default))
	import(`@Assets/images/stepWelcome/${i18n.languages[0]}/meme2.png`).then((img) => setMeme2(img.default))

	const [flipped, setFlipped] = useState(false)

	return (
		<ReactCardFlip containerClassName="w-100" isFlipped={flipped} flipDirection="horizontal">

			<div className="clickable box box-arrow box-secondary h-100" onClick={() => setFlipped(!flipped)}>
				<img src={arrow} alt="" height="300px"/>
			</div>

			<div className="clickable box box-arrow flipped h-100 d-flex flex-column align-content-center justify-content-evenly" onClick={() => setFlipped(!flipped)}>
				<img src={meme1} alt="" />
				<img src={meme2} alt="" />
			</div>
		</ReactCardFlip>
	)
}

export default BoxArrow
