import React from 'react'
import { Col, Row } from 'reactstrap'
import { Trans } from 'react-i18next'
import arrow from '@Assets/images/arrow.svg'
import BoxPeople from '@Pages/StepWelcome/Desktop/Slider/BoxPeople'
import routes from '@Routes/routeConfig'
import { useNavigate } from 'react-router-dom'
import BoxHelloFuture from '@Pages/StepWelcome/Desktop/Slider/BoxHelloFuture'
import BoxSteps from '@Pages/StepWelcome/Desktop/Slider/BoxSteps'
import BoxArrow from '@Pages/StepWelcome/Desktop/Slider/BoxArrow'

const Slider = props => {

	const navigate = useNavigate()

	return (
		<div className="container container-with-sticky-topbar slider w-100">
			<Row>
				<Col md={6}>
					<div className="box-welcome">
						<h1 className="welcome-title"><Trans i18nKey="stepWelcome.title1"/></h1>
						<h4 className="welcome-subtitle">
							<Trans i18nKey="stepWelcome.desktop.slider.title"/>
						</h4>
						<div>
							<button
								className="btn btn-lg btn-primary btn-rounded fw-bold"
								onClick={() => navigate(routes.UNIVERSITY)}
							><Trans i18nKey="stepWelcome.desktop.slider.button"/></button>
						</div>
					</div>
				</Col>
				<Col md={6}>
					<Row>
						<Col md={12}>
							<BoxHelloFuture/>
						</Col>
						<Col md={7}>
							<BoxSteps/>
							<BoxPeople/>
						</Col>
						<Col md={5} className="d-flex">
							<BoxArrow />
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	)
}

export default Slider
