export default [
	'https://assets.wiraki.com/img/network-logos/6177.png',
	'https://assets.wiraki.com/img/network-logos/5740.png',
	'https://assets.wiraki.com/img/network-logos/2401.png',
	'https://assets.wiraki.com/img/network-logos/5143.png',
	'https://assets.wiraki.com/img/network-logos/2393.png',
	'https://assets.wiraki.com/img/network-logos/2408.png',
	'https://assets.wiraki.com/img/network-logos/2400.png',
	'https://assets.wiraki.com/img/network-logos/2404.png',
	'https://assets.wiraki.com/img/network-logos/618.png',
	'https://assets.wiraki.com/img/network-logos/2378.png',
	'https://assets.wiraki.com/img/network-logos/2399.png',
	'https://assets.wiraki.com/img/network-logos/2405.png',
	'https://assets.wiraki.com/img/network-logos/2392.png',
	'https://assets.wiraki.com/img/network-logos/2403.png',
	'https://assets.wiraki.com/img/network-logos/2362.png',
	'https://assets.wiraki.com/img/network-logos/2364.png',
	'https://assets.wiraki.com/img/network-logos/2412.png',
	'https://assets.wiraki.com/img/network-logos/2375.png',
	'https://assets.wiraki.com/img/network-logos/2382.png',
	'https://assets.wiraki.com/img/network-logos/2413.png',
	'https://assets.wiraki.com/img/network-logos/2372.png',
	'https://assets.wiraki.com/img/network-logos/2385.png',
	'https://assets.wiraki.com/img/network-logos/2409.png',
	'https://assets.wiraki.com/img/network-logos/2411.png',
	'https://assets.wiraki.com/img/network-logos/2363.png',
]
