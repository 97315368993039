import React, { useEffect, useState } from 'react'
//Import Image
import 'react-phone-input-2/lib/style.css'
import { Trans, useTranslation } from 'react-i18next'

import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import SelectUniversity from '@Pages/StepUniversity/SelectUniversity'
import SelectFaculty from '@Pages/StepUniversity/SelectFaculty'
import { loadListParticipantsFaculty, loadListParticipantsUniversity, submitUniversity } from '@Store/formVote/actions'
import BoxInfoUniversity from '@Pages/StepUniversity/BoxInfoUniversity'
import BoxInfoFaculty from '@Pages/StepUniversity/BoxInfoFaculty'
import { Card, CardBody, Col, Row } from 'reactstrap'
import LayoutForm from '@Components/Layout/LayoutForm'
import LayoutFormMobile from '@Components/Layout/LayoutFormMobile'
import LayoutFormDesktop from '@Components/Layout/LayoutFormDesktop'

const StepUniversity = props => {

	const { t } = useTranslation()
	const dispatch = useDispatch()

	const currentTab = 'checkVote'

	const [university, setUniversity] = useState()
	const [faculty, setFaculty] = useState()

	const [submitted, setSubmitted] = useState(false)

	const dataCheckVote = useSelector(state => state.FormVote.dataCheckVote)

	useEffect(() => {
		setUniversity(dataCheckVote.university)
		setFaculty(dataCheckVote.faculty)
		//if (dataCheckVote.degreeType) setUniversityCycle(dataCheckVote.degreeType)
		//if (dataCheckVote.attendingYear) setAttendingYear(dataCheckVote.attendingYear)
	}, [dataCheckVote])

	useEffect(() => {
		if (university?.id) dispatch(loadListParticipantsUniversity(university?.id))
	}, [university])

	useEffect(() => {
		if (university?.id && faculty?.id) dispatch(loadListParticipantsFaculty(university?.id, faculty?.id))
	}, [faculty])

	const onSubmit = (data) => {
		setSubmitted(true)
		if (!university) return null
		if (!faculty) return null
		dispatch(submitUniversity(dataCheckVote))
	}
	const {
		register,
		handleSubmit,
		formState: { errors },
		formState,
		setValue,
	} = useForm({
		mode: 'onBlur',
		reValidateMode: 'onChange',
	})

	const buttonNextActive = faculty && university

	return (
		<LayoutForm
			currentTab={currentTab}
			handleSubmit={handleSubmit(onSubmit)}
		>
			<LayoutFormMobile
				stickyButtonLabel={t('stepUniversity.button')}
				buttonNextActive={buttonNextActive}
			>
				<div className="title fw-bold"><Trans i18nKey="stepUniversity.title"/></div>
				<div className="subtitle fw-light mb-3"><Trans i18nKey="stepUniversity.subtitle"/></div>
				<Card>
					<CardBody>
						<div className="container-select-university">
							<label><Trans i18nKey="stepUniversity.inputUniversity.label"/></label>
							<SelectUniversity
								className="mb-0"
								university={university}
								submitted={submitted}
							/>
							<hr className="mt-0 mb-1"/>
							<BoxInfoUniversity/>
						</div>
					</CardBody>
				</Card>
				{university && (
					<Card>
						<CardBody>
							<div className="container-select-faculty">
								<label><Trans i18nKey="stepUniversity.inputFaculty.label"/></label>
								<SelectFaculty
									className="mb-0"
									faculty={faculty}
									universityId={university?.id}
									submitted={submitted}
								/>
								<hr className="mt-0 mb-1"/>
								<BoxInfoFaculty/>
							</div>
						</CardBody>
					</Card>
				)}
			</LayoutFormMobile>
			<LayoutFormDesktop
				currentTab={currentTab}
				buttonPreviousText={t('stepUniversity.buttonBack')}
				buttonNextText={t('stepUniversity.buttonNext')}
				buttonNextActive={buttonNextActive}
				title={(
					<>
						<div className="title fw-bold"><Trans i18nKey="stepUniversity.title"/></div>
						<div className="subtitle fw-light mb-3"><Trans i18nKey="stepUniversity.subtitle"/></div>
					</>
				)}
			>
				<Row className="box-content-card h-100">
					<Col md={6} className="border-right">
						<div className="container-select-university container-form">
							<label><Trans i18nKey="stepUniversity.inputUniversity.label"/></label>
							<SelectUniversity
								className="mb-0"
								university={university}
								submitted={submitted}
							/>
						</div>
					</Col>
					<Col md={6}>
						<BoxInfoUniversity/>
					</Col>
					<Col md={6} className="border-right">
						<div className="container-select-faculty container-form">
							<label><Trans i18nKey="stepUniversity.inputFaculty.label"/></label>
							<SelectFaculty
								className="mb-0"
								faculty={faculty}
								universityId={university?.id}
								submitted={submitted}
							/>
						</div>
					</Col>
					<Col md={6}>
						<BoxInfoFaculty/>
					</Col>
				</Row>
			</LayoutFormDesktop>
		</LayoutForm>
	)
}

export default StepUniversity
