import React, { useState } from 'react'
import { loadDataUniversity, saveFacultyData, saveUniversityData } from '@Store/formVote/actions'
import { Trans } from 'react-i18next'
import AsyncSelect from 'react-select/async/dist/react-select.esm'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import InputBox from '@Compounds/InputBox'
import { formatAutocomplete, selectAutocompleteStyle } from '@Pages/StepUniversity/dropdownTools'

const getUniversityList = (input, callback) => {
	axios.get(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/public/networks?fields=id,name,logo,countryCode,domain&limit=40&term=${input}`)
		.then(({ data }) => {
			callback(data.map((item) => ({ ...item, label: item.name, countryCode: item.countryCode, domain: item.domain })))
		})
}

const SelectUniversity = props => {

	const { className, university, submitted } = props

	const dispatch = useDispatch()

	const [useAutocompleteStyle, setUseAutocompleteStyle] = useState(false)
	const [isTouched, setIsTouched] = useState(false)

	const isInvalid = (submitted && !university) || (isTouched && !university)

	return (
		<InputBox
			className={className}
			errors={submitted && !university || isTouched && !university}
			touched={isTouched || university}
		>
			<AsyncSelect
				value={university}
				onChange={(e) => {
					dispatch(loadDataUniversity(e.id))
					dispatch(saveFacultyData(null))
				}}
				isMulti={false}
				onInputChange={(e, { action }) => {
					if (action === 'input-change') {
						dispatch(saveUniversityData(null))
					} else if (action === 'menu-close') {
						setUseAutocompleteStyle(false)
						document.activeElement.blur()
					}
				}}
				loadOptions={getUniversityList}
				classNamePrefix="autocomplete"
				className={`select-box no-pipe-dropdown w-100 ${!university || 'valid'} ${isInvalid ? 'invalid' : ''}`}
				placeholder={<Trans i18nKey="stepUniversity.inputUniversity.placeholder"/>}
				styles={selectAutocompleteStyle(useAutocompleteStyle)}
				onKeyDown={(e) => {
					if (['Backspace', 'Delete'].indexOf(e.code) > -1 && e.target.value === '') {
						dispatch(saveUniversityData(null))
					}
				}}
				onFocus={(e) => {
					setIsTouched(false)
					// Scroll to element
					const positionY = e.target.getBoundingClientRect().top + +document.documentElement.scrollTop
					window.scrollTo(0, positionY - 180)
					setUseAutocompleteStyle(true)
				}}
				onBlur={() => {
					setIsTouched(true)
				}}
				formatOptionLabel={({ label, logo }, { inputValue }) => formatAutocomplete(inputValue, label, logo)}
				noOptionsMessage={() => (<Trans i18nKey="stepUniversity.inputUniversity.noOptions"/>)}
			/>
		</InputBox>
	)
}

export default SelectUniversity
