import Cookies from 'js-cookie'
import moment from 'moment'
import _ from 'lodash'

import {
	CHECK_COUNTDOWN,
	LOAD_LOCAL_STORAGE_STATE,
	REDEEM_REWARD_SUBMITTED,
	REDIRECTED, SAVE_ATTENDING_YEAR,
	SAVE_FACULTY_DATA,
	SAVE_GLOBAL_STATS,
	SAVE_LIST_PARTICIPANTS_FACULTY,
	SAVE_LIST_PARTICIPANTS_UNIVERSITY,
	SAVE_UNIVERSITY_DATA,
	SET_REFERRAL_VALID,
	SUBMIT_REDEEM_REWARD,
	SUBMIT_UNIVERSITY,
	SUBMIT_VOTES,
} from './actionTypes'
import routes from '@Routes/routeConfig'
import { Trans } from 'react-i18next'

const INIT_STATE = {
	localStorageLoaded: false,
	redirect: null,
	referral: {
		isValid: false,
		fullName: null,
		people: [],
		referrerCode: null,
		initials: '',
	},
	steps: {
		1: { step: 'checkVote', isAccessible: true, label: <Trans i18nKey="steps.selectUniversity"/>, url: routes.UNIVERSITY },
		2: { step: 'castVotes', isAccessible: false, label: <Trans i18nKey="steps.vote"/>, url: routes.VOTE },
		3: { step: 'redeemReward', isAccessible: false, label: <Trans i18nKey="steps.redeem"/>, url: routes.REDEEM },
	},
	dataCheckVote: {
		university: null,
		faculty: null,
		availablePlaces: 0,
		graduatingDate: {
			month: null,
			year: null
		}
	},
	dataCastVotes: {
		firstVote: null,
		secondVote: null,
		thirdVote: null,
	},
	dataRedeemReward: {
		nameSurname: null,
		universityEmail: null,
		nameSurnameReferrer: null,
		phoneNumber: null,
		attendingYear: null,
	},
	listParticipants: {
		loaded: true,
		university: [],
		faculty: [],
	},
	disableSubmit: false,
	submitError: null
}
// Local Storage Functions
const LOCAL_STORAGE_NAME = 'formState'

function updateLocalStorage (state) {
	let newState = _.cloneDeep(state)
	newState.redirect = null
	newState.disableSubmit = false
	newState.submitError = null
	delete newState.countdown
	window.localStorage.setItem(LOCAL_STORAGE_NAME, JSON.stringify(newState))
}

function getLocalStorage () {
	return JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_NAME))
}

function cleanLocalStorage () {
	window.localStorage.removeItem(LOCAL_STORAGE_NAME)
}

const FormVote = (state = INIT_STATE, action) => {
	switch (action.type) {
		case CHECK_COUNTDOWN:
			let countdown = Cookies.get('countdown')

			if (!countdown || moment().isAfter(countdown)) {
				const expires = moment().add(1, 'hours').toISOString()
				Cookies.set('countdown', expires, { expires: 1 })
				countdown = expires
			}
			return {
				...state,
				countdown
			}
		case REDIRECTED:
			updateLocalStorage(state)
			return {
				...state,
				redirect: null
			}
		case SAVE_GLOBAL_STATS:
			return {
				...state,
				globalStats: action.payload
			}
		case LOAD_LOCAL_STORAGE_STATE:
			const a = getLocalStorage()
			return {
				...state,
				...a,
				localStorageLoaded: true,
			}
		case SUBMIT_UNIVERSITY:
			state.steps['2'].isAccessible = true
			state.dataCheckVote = action.payload
			updateLocalStorage(state)
			return {
				...state,
				redirect: '/cast-votes'
			}
		case SUBMIT_VOTES:
			state.steps['3'].isAccessible = true
			state.dataCastVotes = action.payload
			updateLocalStorage(state)
			return {
				...state,
				redirect: '/redeem-reward'
			}
		case SUBMIT_REDEEM_REWARD:
			state.dataRedeemReward = action.payload
			updateLocalStorage(state)
			return {
				...state,
				disableSubmit: true
			}
		case SET_REFERRAL_VALID:
			state.referral = {
				isValid: true,
				referrerCode: action.payload.referrerCode,
				fullName: action.payload.fullName,
				people: action.payload.people,
				inviteWithVote: action.payload.inviteWithVote,
				//initials: action.payload.fullName.charAt(0) + action.payload.fullName.split(' ').pop().charAt(0)
			}
			updateLocalStorage(state)
			return {
				...state
			}
		case SAVE_UNIVERSITY_DATA:
			if (!action.payload) {
				state = {
					...state,
					dataCheckVote: {
						...state.dataCheckVote,
						university: null
					}
				}

				updateLocalStorage(state)
				return state
			}
			state = {
				...state,
				dataCheckVote: {
					...state.dataCheckVote,
					university: {
						...action.payload,
						label: action.payload ? action.payload.name : null,
						logo: action.payload ? action.payload.logo : null,
						id: action.payload ? action.payload.id : null
					},
					availablePlaces: action.payload ? action.payload.places : 0
				},
				listParticipants: {
					...state.listParticipants,
					loaded: false,
				}
			}

			updateLocalStorage(state)
			return state
		case SAVE_FACULTY_DATA:
			if (!action.payload) {
				state = {
					...state,
					dataCheckVote: {
						...state.dataCheckVote,
						faculty: null
					}
				}

				updateLocalStorage(state)
				return state
			}
			state = {
				...state,
				dataCheckVote: {
					...state.dataCheckVote,
					faculty: {
						...action.payload,
						label: action.payload.name
					}
				}
			}

			updateLocalStorage(state)
			return state
		case REDEEM_REWARD_SUBMITTED:
			if (action.payload.accessToken) {
				cleanLocalStorage()
				// Set cookie for redirect to me.wiraki.com
				Cookies.set('alreadyVoted', 'true', { expires: 365 })

				window.location.href = `${process.env.REACT_APP_USER_AREA_URL}/login-with-token?t=${action.payload.accessToken}`
				return {
					...state,
					submitError: null,
				}
			} else {
				return {
					...state,
					submitError: action.payload,
					disableSubmit: false
				}
			}
		case SAVE_LIST_PARTICIPANTS_UNIVERSITY:
			state = {
				...state,
				listParticipants: {
					...state.listParticipants,
					university: action.payload,
					loaded: true
				}
			}
			updateLocalStorage(state)
			return state
		case SAVE_LIST_PARTICIPANTS_FACULTY:
			state = {
				...state,
				listParticipants: {
					...state.listParticipants,
					faculty: action.payload,
					loaded: true
				}
			}
			updateLocalStorage(state)
			return state
		case SAVE_ATTENDING_YEAR:

			state = {
				...state,
				dataRedeemReward: {
					...state.dataRedeemReward,
					attendingYear: {
						...state.dataRedeemReward?.attendingYear,
						value: action.payload.degreeType,
						obj: {
							attendingYear: action.payload.attendingYear,
							degreeType: action.payload.degreeType
						}
					}
				}
			}
			updateLocalStorage(state)
			return state
		default:
			return state
	}
}

export default FormVote
