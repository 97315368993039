import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Trans } from 'react-i18next'
import { toggleModal } from '@Store/template/actions'
import { MODAL_STEP_BLOCKED } from '@Modals/index'

const FormSteps = props => {

	const dispatch = useDispatch()

	const steps = {
		1: <Trans i18nKey="steps.selectUniversity"/>,
		2: <Trans i18nKey="steps.vote"/>,
		3: <Trans i18nKey="steps.redeem"/>,
	}

	const stepsData = useSelector(state => state.FormVote.steps)

	useEffect(() => {

	}, [stepsData])

	let advancement = 'progression-0'
	if (props.currentTab === 'welcome') {

	} else if (stepsData[3].isAccessible) {
		advancement = 'progression-3'
	} else if (stepsData[2].isAccessible) {
		advancement = 'progression-2'
	}

	const handleClickStep = (openModal, step) => {
		if (!openModal) return
		dispatch(toggleModal(MODAL_STEP_BLOCKED, true, { step }))
	}

	return (
		<div className={`navigation-menu ${props.className}`}>
			<div className={`menu-prorgession ${advancement}`}/>
			{Object.keys(stepsData).map((k, i) => {
				const isCurrent = stepsData[k].step === props.currentTab
				const isActive = stepsData[k].isAccessible
				const showCheck = !isCurrent && isActive && stepsData[parseInt(k) + 1] && stepsData[parseInt(k) + 1].isAccessible
				return (
					<Link
						to={isActive ? stepsData[k].url : '#'}
						key={k}
						className={`menu-item ${isCurrent ? 'current' : ''} ${isActive ? 'active' : ''}`}
						onClick={() => handleClickStep(!isActive, stepsData[k].step)}
					>
						<div className="step-container">
							<div className="step">
								{showCheck ? (
									<span><i className="fa fa-check"/></span>
								) : (
									<span>{k}</span>
								)}
							</div>
						</div>
						<div className="name">{steps[k]}</div>
					</Link>
				)
			})}
		</div>
	)
}

export default FormSteps
