import React from 'react'
import { Col, Row } from 'reactstrap'
import Logo from '@Icons/Logo'
import EmailRoundedIcon from '@mui/icons-material/EmailRounded'
import Instagram from '@Icons/Instagram'
import { Trans } from 'react-i18next'

const Footer = props => {

	return (
		<footer className="py-5">
			<div className="container">
				<Row>
					<Col md={4}>
						<div className="w-75 d-flex flex-column">
							<div className="logo"><Logo/> Wiraki</div>
							<div className="mt-3"><Trans i18nKey="stepWelcome.desktop.footer.slogan"/></div>
							<div className="mt-5">© Wiraki Ltd 2021</div>
							<div><Trans i18nKey="stepWelcome.desktop.footer.rights"/></div>
						</div>
					</Col>
					<Col md={4} className="d-flex justify-content-center">
						<div className="w-75 d-flex flex-column pt-3">
							<h4 className="text-white"><Trans i18nKey="stepWelcome.desktop.footer.contactUs"/></h4>
							{/*<div className="d-flex align-items-center mt-3">
								<WhatsAppIcon/>
								<span className="ms-2">Whatsapp</span>
							</div>*/}
							<div className="d-flex align-items-center mt-2">
								<a target="_blank" href="https://instagram.com/wirakiapp?igshid=YmMyMTA2M2Y=" className="text-white">
									<Instagram style={{ width: '24px', height: '20px', padding: '0 2px' }}/>
									<span className="ms-2">Wiraki</span>
								</a>
							</div>
							<div className="d-flex align-items-center mt-2">
								<a href="mailto:info@wiraki.com" className="text-white">
									<EmailRoundedIcon/>
									<span className="ms-2">info@wiraki.com</span>
								</a>
							</div>
						</div>
					</Col>
					<Col md={4} className="d-flex justify-content-end">
						<div className="w-75 d-flex flex-column justify-content-end">
							<a href={`${process.env.REACT_APP_WIRAKI_WEBSITE}/en/privacy`}>
								<h5 className="text-white"><Trans i18nKey="stepWelcome.desktop.footer.privacy"/></h5>
							</a>
							<a href={`${process.env.REACT_APP_WIRAKI_WEBSITE}/en/terms-of-service`}>
								<h5 className="text-white mb-0"><Trans i18nKey="stepWelcome.desktop.footer.tos"/></h5>
							</a>
						</div>
					</Col>
				</Row>
			</div>
		</footer>
	)
}

export default Footer
