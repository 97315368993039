import PropTypes from 'prop-types'

const Flag = props => {

	const FlagIcon = props.flag

	return (
		<FlagIcon
			className={props.className}
			style={props.style}
			alt="" />
	)
}

Flag.propTypes = {
	flag: PropTypes.any.isRequired,
	style: PropTypes.object,
	className: PropTypes.string,
}

export default Flag
