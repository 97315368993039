import React, { useEffect, useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { Trans } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import pikachu from '@Assets/images/pikachu.png'
import { toggleModal } from '@Store/template/actions'
import { MODAL_STEP_BLOCKED } from '@Modals/index'

const ModalStepBlocked = () => {

	const dispatch = useDispatch()

	const [isOpen, setOpen] = useState(false)

	const dataModal = useSelector(state => state.Template.modal)

	useEffect(() => {
		setOpen(!!dataModal[MODAL_STEP_BLOCKED]?.status)
	}, [dataModal])

	const step = dataModal[MODAL_STEP_BLOCKED]?.data?.step

	if(!step) return null

	return (
		<React.Fragment>
			<Modal
				centered
				size="md"
				isOpen={isOpen}
				toggle={() => dispatch(toggleModal(MODAL_STEP_BLOCKED, false))}
				contentClassName="border-0"
				id="modal-step-blocked"
			>
				<ModalBody className="px-3 py-4 text-center">
					<div className="">
						<h3 className="modal-title">
							<Trans i18nKey={`modalStepBlocked.${step}.title`}/>
						</h3>
						<img src={pikachu} alt=""/>
						<p className="modal-text">
							<Trans i18nKey={`modalStepBlocked.${step}.text`}/>
						</p>
					</div>

					<button
						onClick={() => dispatch(toggleModal(MODAL_STEP_BLOCKED, false))}
						type="button"
						className="btn btn-rounded btn-lg btn-secondary mt-3 d-md-none"
						data-dismiss="modal"
					>
						<Trans i18nKey={`modalStepBlocked.${step}.cta`}/>
					</button>

					<button
						onClick={() => dispatch(toggleModal(MODAL_STEP_BLOCKED, false))}
						type="button"
						className="btn btn-link btn-lg text-secondary fw-bold mt-3 d-none d-md-inline-block"
						data-dismiss="modal"
					>
						<Trans i18nKey={`modalStepBlocked.${step}.cta`}/>
					</button>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}
export default ModalStepBlocked
