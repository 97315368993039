import React, { useEffect, useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { Trans } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { MODAL_REWARD } from '@Modals/index'
import { toggleModal } from '@Store/template/actions'

const ModalReward = () => {

	const dispatch = useDispatch()

	const [isOpen, setOpen] = useState(false)

	const dataModal = useSelector(state => state.Template.modal)

	useEffect(() => {
		setOpen(!!dataModal[MODAL_REWARD]?.status)
	}, [dataModal])

	return (
		<React.Fragment>
			<Modal
				centered
				size="md"
				isOpen={isOpen}
				toggle={() => dispatch(toggleModal(MODAL_REWARD, false))}
				className="m-auto"
				contentClassName="border-0"
				id="modal-reward"
			>
				<ModalBody className="text-center">
					<div>
						<h3 className="modal-title">
							<Trans i18nKey="modalReward.title"/>
						</h3>
						<p className="modal-text">
							<Trans i18nKey="modalReward.text"/>
						</p>
					</div>
					<button
						onClick={() => dispatch(toggleModal(MODAL_REWARD, false))}
						type="button"
						className="btn btn-link-secondary btn-lg"
						data-dismiss="modal"
					>
						<Trans i18nKey="modalReward.button"/>
					</button>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}
export default ModalReward
