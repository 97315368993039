import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { formatDate } from '@Helpers/number_helper'
import { Trans } from 'react-i18next'
import { checkCountdown } from '@Store/formVote/actions'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'

const Countdown = props => {

	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(checkCountdown())
	}, [])

	const countdown = useSelector(state => state.FormVote.countdown)

	function calculateTimeLeft () {

		const difference = moment(countdown).diff(moment(new Date()), 'seconds')

		let timeLeft = {}
		if (difference > 0) {
			timeLeft = {
				days: Math.floor(difference / (60 * 60 * 24)),
				hours: Math.floor((difference / (60 * 60)) % 24),
				minutes: Math.floor((difference / 60) % 60),
				seconds: Math.floor((difference) % 60),
			}
		}

		return timeLeft
	}

	const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())
	const [showCountdown, setShowCountdown] = useState(false)

	useEffect(() => {
		// This will prevent NaN from showing
		if (!showCountdown) {
			setTimeLeft(calculateTimeLeft())
			setShowCountdown(true)
		}
		const id = setTimeout(() => {
			setTimeLeft(calculateTimeLeft())
		}, 1000)
		return () => { clearTimeout(id) }

	})

	function drawTimer () {

		return (
			<>
				{timeLeft.days > 0 && (
					<>
						<div className="countdown-element">
							<span className="number">{('0' + timeLeft.days).slice(-2)}</span>
							<span className="text"><Trans i18nKey="time.day" count={timeLeft.days}/></span>
						</div>
						<div className="countdown-element">
							<span className="number">:</span>
							<span className="text">&nbsp;</span>
						</div>
					</>
				)}
				{(timeLeft.days > 0 || timeLeft.hours > 0) && (
					<>
						<div className="countdown-element">
							<span className="number">{('0' + timeLeft.hours).slice(-2)}</span>
							<span className="text"><Trans i18nKey="time.hour" count={timeLeft.hours}/></span>
						</div>
						<div className="countdown-element">
							<span className="number">:</span>
							<span className="text">&nbsp;</span>
						</div>
					</>
				)}
				{(timeLeft.days > 0 || timeLeft.hours > 0 || timeLeft.minutes > 0) && (
					<>
						<div className="countdown-element">
							<span className="number">{('0' + timeLeft.minutes).slice(-2)}</span>
							<span className="text"><Trans i18nKey="time.minute" count={timeLeft.minutes}/></span>
						</div>
						<div className="countdown-element">
							<span className="number">:</span>
							<span className="text">&nbsp;</span>
						</div>
					</>
				)}
				{(timeLeft.days > 0 || timeLeft.hours > 0 || timeLeft.minutes > 0 || timeLeft.seconds > 0) && (
					<div className="countdown-element">
						<span className="number">{('0' + timeLeft.seconds).slice(-2)}</span>
						<span className="text"><Trans i18nKey="time.second" count={timeLeft.seconds}/></span>
					</div>
				)}
			</>
		)
	}

	if (!countdown || !showCountdown) return null

	return (
		<React.Fragment>
			{props.compact && !_.isEmpty(timeLeft) ? (
				<React.Fragment>
					<div className={`${props.className} countdown countdown-compact`} style={props.style}>
						{props.desktop && (
							<label><Trans i18nKey="countdown"/></label>
						)}
						{props.icon}
						{formatDate(timeLeft, ':')}
					</div>
				</React.Fragment>
			) : (
				<div className={`${props.className} countdown`} style={props.style}>
					{drawTimer()}
				</div>
			)}
		</React.Fragment>
	)
}

Countdown.propTypes = {
	compact: PropTypes.bool,
	desktop: PropTypes.bool,
	icon: PropTypes.any,
}

export default Countdown
