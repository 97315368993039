import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import _ from 'lodash'

// Login Redux States
import { CHECK_REFERRAL, LOAD_DATA_FACULTY, LOAD_DATA_UNIVERSITY, LOAD_GLOBAL_STATS, LOAD_LIST_PARTICIPANTS_FACULTY, LOAD_LIST_PARTICIPANTS_UNIVERSITY, SUBMIT_REDEEM_REWARD } from './actionTypes'
import axios from 'axios'
import {
	loadDataFaculty,
	loadDataUniversity,
	redeemRewardSubmitted, saveAttendingYear,
	saveFacultyData,
	saveGlobalStats,
	saveListParticipantsFaculty,
	saveListParticipantsUniversity,
	saveUniversityData,
	setTokenAsValid
} from '@Store/actions'
import { handleError } from '@Store/helpers'
import {
	ERROR_MESSAGE_GENERIC,
	ERROR_MESSAGE_MISSING_ATTENDING_YEAR,
	ERROR_MESSAGE_MISSING_DEGREE_TYPE,
	ERROR_MESSAGE_MISSING_FACULTY,
	ERROR_MESSAGE_MISSING_FIRST_VOTE,
	ERROR_MESSAGE_MISSING_NAME,
	ERROR_MESSAGE_MISSING_SECOND_VOTE,
	ERROR_MESSAGE_MISSING_THIRD_VOTE,
	ERROR_MESSAGE_MISSING_UNIVERSITY,
	ERROR_MESSAGE_MISSING_UNIVERSITY_EMAIL
} from '@Errors/index'

const getSubmitPayload = (state) => state.FormVote

function * getDataReferral ({ payload }) {
	try {
		if (payload) {
			const { data } = yield call(() => axios.get(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/public/info/referrers/${payload}`, { withCredentials: true }))
			yield put(setTokenAsValid({ ...data, referrerCode: payload }))
			if (data.network) {
				yield put(loadDataUniversity(data.network))
			}
			if (data.faculty) {
				yield put(loadDataFaculty(data.faculty))
			}
			if(data.attendingYear && data.degreeType) {
				yield put(saveAttendingYear(data.degreeType, data.attendingYear))
			}
		}
	} catch (error) {
		handleError(error)
	}
}

function * getDataUniversity ({ payload: universityId }) {
	try {
		if (universityId) {
			const { data: network } = yield call(() => axios.get(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/public/info/networks/${universityId}`))
			yield put(saveUniversityData(network))
		}
	} catch (error) {
		handleError(error)
	}
}

function * getDataFaculty ({ payload: facultyId }) {
	try {
		if (facultyId) {
			const { data: faculty } = yield call(() => axios.get(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/public/faculties/${facultyId}`))
			yield put(saveFacultyData(faculty))
		}
	} catch (error) {
		handleError(error)
	}
}

function * getListParticipantsUniversity ({ payload }) {
	try {
		const data = yield call(() => getParticipants({ universityId: payload }))
		return yield put(saveListParticipantsUniversity(data))
	} catch (error) {
		handleError(error)
	}
}

function * getListParticipantsFaculty ({ payload: { universityId, facultyId } }) {
	try {
		const data = yield call(() => getParticipants({ universityId, facultyId }))
		return yield put(saveListParticipantsFaculty(data))
	} catch (error) {
		handleError(error)
	}
}

function * getParticipants (params) {
	try {
		let queryString = `network=${params.universityId}`
		if (!_.isEmpty(params?.facultyId)) {
			queryString += `&faculty=${params.facultyId}`
		}
		const { data } = yield call(() => axios.get(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/public/people?${queryString}`))
		return data
	} catch (error) {
		handleError(error)
	}
}

function * getGlobalStats () {
	try {
		const { data } = yield call(() => axios.get(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/public/stats`))
		return yield put(saveGlobalStats(data))
	} catch (error) {
		handleError(error)
	}
}

function * submitForm () {
	const stateData = yield select(getSubmitPayload)

	// Check all fields has been filled
	if (!stateData.dataCheckVote) yield put(redeemRewardSubmitted({ errorMessage: ERROR_MESSAGE_GENERIC }))
	if (!stateData.dataCheckVote?.university?.id) yield put(redeemRewardSubmitted({ errorMessage: ERROR_MESSAGE_MISSING_UNIVERSITY }))
	if (!stateData.dataCheckVote?.faculty?.id) yield put(redeemRewardSubmitted({ errorMessage: ERROR_MESSAGE_MISSING_FACULTY }))

	if (!stateData.dataCastVotes) yield put(redeemRewardSubmitted({ errorMessage: ERROR_MESSAGE_GENERIC }))
	if (!stateData.dataCastVotes?.firstVote) yield put(redeemRewardSubmitted({ errorMessage: ERROR_MESSAGE_MISSING_FIRST_VOTE }))
	if (!stateData.dataCastVotes?.secondVote) yield put(redeemRewardSubmitted({ errorMessage: ERROR_MESSAGE_MISSING_SECOND_VOTE }))
	if (!stateData.dataCastVotes?.thirdVote) yield put(redeemRewardSubmitted({ errorMessage: ERROR_MESSAGE_MISSING_THIRD_VOTE }))

	if (!stateData.dataRedeemReward) yield put(redeemRewardSubmitted({ errorMessage: ERROR_MESSAGE_GENERIC }))
	if (!stateData.dataRedeemReward?.universityEmail) yield put(redeemRewardSubmitted({ errorMessage: ERROR_MESSAGE_MISSING_UNIVERSITY_EMAIL }))
	if (!stateData.dataRedeemReward?.name) yield put(redeemRewardSubmitted({ errorMessage: ERROR_MESSAGE_MISSING_NAME }))
	if (!stateData.dataRedeemReward?.attendingYear?.obj?.attendingYear) yield put(redeemRewardSubmitted({ errorMessage: ERROR_MESSAGE_MISSING_ATTENDING_YEAR }))
	if (!stateData.dataRedeemReward?.attendingYear?.obj?.degreeType) yield put(redeemRewardSubmitted({ errorMessage: ERROR_MESSAGE_MISSING_DEGREE_TYPE }))

	try {
		const obj = {
			network: stateData.dataCheckVote.university.id,
			faculty: stateData.dataCheckVote.faculty.id,
			firstVote: stateData.dataCastVotes.firstVote,
			secondVote: stateData.dataCastVotes.secondVote !== '' ? stateData.dataCastVotes.secondVote : null,
			thirdVote: stateData.dataCastVotes.thirdVote !== '' ? stateData.dataCastVotes.thirdVote : null,
			networkEmail: stateData.dataRedeemReward.universityEmail,
			fullName: stateData.dataRedeemReward.name,
			referrerCode: stateData.referral.referrerCode,
			phoneNumber: stateData.dataRedeemReward.phone !== '' ? stateData.dataRedeemReward.phone : null,
			invitedToVote: stateData.dataCastVotes.invitedToVote,
			attendingYear: stateData.dataRedeemReward.attendingYear.obj.attendingYear,
			degreeType: stateData.dataRedeemReward.attendingYear.obj.degreeType,
		}
		const response = yield call(() => axios.post(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/public/forms`, obj))
		yield put(redeemRewardSubmitted({ accessToken: response.data.token.access_token }))
	} catch (error) {
		const response = error.response?.data
		/* Check if there is fullName error */
		const fullNameMessage = response?.errors[0]?.code
		let code = fullNameMessage ? fullNameMessage : ''

		yield put(redeemRewardSubmitted({ errorMessage: code }))
		/*
				let errorMessage = 'error'
				if (errorResponse) {
					switch (errorResponse.code) {
						case 'NETWORK_NOT_FOUND':
							errorMessage = 'Error: network not found'
							break
						case 'INVALID_EMAIL_DOMAIN_NETWORK':
							errorMessage = 'Invalid email address'
							break
						case 'ALREADY_VOTED':
						case 'ALREADY_BELONG_TO_NETWORK':
							errorMessage = 'A user with this email already voted'
							break
						case 'VOTED_YOURSELF':
							errorMessage = 'You cannot vote for yourself'
							break
						case 'DUPLICATE_VOTE':
							errorMessage = 'Cannot vote the same persone multiple times'
							break
					}
				}
				yield put(redeemRewardSubmitted({ errorMessage }))*/
	}
}

function * FormVoteSaga () {
	yield all([
		takeEvery(CHECK_REFERRAL, getDataReferral),
		takeEvery(LOAD_DATA_UNIVERSITY, getDataUniversity),
		takeEvery(LOAD_DATA_FACULTY, getDataFaculty),
		takeEvery(SUBMIT_REDEEM_REWARD, submitForm),

		takeEvery(LOAD_LIST_PARTICIPANTS_UNIVERSITY, getListParticipantsUniversity),
		takeEvery(LOAD_LIST_PARTICIPANTS_FACULTY, getListParticipantsFaculty),
		takeEvery(LOAD_GLOBAL_STATS, getGlobalStats),
	])
}

export default FormVoteSaga
