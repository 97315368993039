import React, { useState } from 'react'
import ReactCardFlip from 'react-card-flip'
import { useNavigate } from 'react-router-dom'
import routes from '@Routes/routeConfig'
import { Trans } from 'react-i18next'

const BoxVote = props => {

	const [flipped, setFlipped] = useState(false)

	const navigate = useNavigate()

	return (
		<ReactCardFlip containerClassName="w-100" isFlipped={flipped} flipDirection="horizontal">

			<div style={{minHeight: '190px'}} className="clickable box h-100 d-flex flex-column justify-content-evenly" onClick={() => setFlipped(!flipped)}>
				<div style={{fontSize: '46px'}}>👏</div>
				<div className="fw-bold"><Trans i18nKey="stepWelcome.flippingBoxes.boxVote.front" /></div>
			</div>

			<div style={{minHeight: '190px'}} className="clickable box flipped h-100 d-flex flex-column justify-content-evenly" onClick={() => setFlipped(!flipped)}>
				<div style={{fontSize: '46px'}} className="align-self-start">👏</div>
				<div className="fs-14"><Trans i18nKey="stepWelcome.flippingBoxes.boxVote.flipped" /></div>
				<div className="fs-14 clickable text-primary fw-bold mt-2" onClick={() => navigate(routes.UNIVERSITY)}><Trans i18nKey="stepWelcome.flippingBoxes.boxVote.cta" /></div>
			</div>

		</ReactCardFlip>
	)
}

export default BoxVote
