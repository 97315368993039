import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import routes from '@Routes/routeConfig'
import TalentsCarousel from '@Compounds/TalentsCarousel'
import BoxInfos from '@Pages/StepWelcome/BoxInfos'
import LoginRoundedIcon from '@mui/icons-material/LoginRounded'
import StickyButton from '@Atoms/StickyButton'
import { Col, Row } from 'reactstrap'
import { formatCurrency } from '@Helpers/number_helper'
import BoxArrow from '@Pages/StepWelcome/Mobile/BoxArrow'
import BoxVote from '@Pages/StepWelcome/Mobile/BoxVote'
import BoxVotesReceived from '@Pages/StepWelcome/Mobile/BoxVotesReceived'

const infos = [
	{ text: <Trans i18nKey="stepWelcome.howItWorks_1" components={{ primary: <span className="text-primary fw-bold"/> }}/>, img: <>👩🏼👨🏻👩🏽</> },
	{ text: <Trans i18nKey="stepWelcome.howItWorks_2" components={{ primary: <span className="text-primary fw-bold"/> }}/>, img: <>👩🏼<br/>👨🏻👩🏽<br/>👩🏼👨🏻👩🏽</> },
	{
		text: <Trans i18nKey="stepWelcome.howItWorks_3" components={{ primary: <span className="text-primary fw-bold"/> }}/>,
		img: <span style={{ position: 'relative', fontSize: '1.2rem' }}>👩🏼<span style={{ fontSize: '1.3rem', position: 'absolute', left: '6px', top: '4px' }}>🔍</span></span>
	},
	{ text: <Trans i18nKey="stepWelcome.howItWorks_4" components={{ primary: <span className="text-primary fw-bold"/> }}/>, img: <span style={{ fontSize: '1.4rem' }}>💰</span> },
]

const Mobile = props => {

	const { t } = useTranslation()
	const navigate = useNavigate()

	const [bgSecondary, setBgSecondary] = useState(false)

	return (
		<div className="d-md-none" id="step-welcome">
			<div className="container">
				<h1 className="welcome-title" style={{ marginBottom: '18px' }}><Trans i18nKey="stepWelcome.title1"/></h1>
				<Link
					to={routes.UNIVERSITY}
					className="btn btn-lg btn-rounded btn-secondary px-4 fw-bold"
					style={{ marginBottom: '28px' }}
				>
					<Trans i18nKey="stepWelcome.button"/>
				</Link>
			</div>

			{/* SECTION */}
			<div className={`slider my-4 d-flex flex-row py-4 ${bgSecondary ? 'bg-secondary' : 'bg-secondary-light'}`}>
				<div className="container">
					<Row>
						<Col xs={6}>
							<BoxVote/>
							<BoxVotesReceived/>
						</Col>
						<Col xs={6}>
							<BoxArrow setBgSecondary={setBgSecondary}/>
						</Col>
					</Row>
				</div>
			</div>
			{/* /SECTION */}


			<div className="container">
				<div className="welcome-subtitle"><Trans i18nKey="stepWelcome.title2"/></div>
				<table className="welcome-table-info">
					<tbody>
					{infos.map((i, k) => (
						<tr className="welcome-list-info" key={k}>
							<td className="info-img">{i.img}</td>
							<td className="info-text">{i.text}</td>
						</tr>
					))}
					</tbody>
				</table>
			</div>
			<div id="cards-talents">
				<h2><Trans i18nKey="stepWelcome.titleCarouselTalents"/></h2>
				<TalentsCarousel/>
			</div>
			<div className="container">
				<BoxInfos/>
				<hr/>
				<div className="text-center">
					<Trans i18nKey="stepWelcome.alreadyVoted" components={{ a: <a className="text-secondary" href={process.env.REACT_APP_USER_AREA_URL}/>, icon: <LoginRoundedIcon/> }}/>
				</div>
				<StickyButton
					buttonText={t('stepWelcome.button')}
					onClick={() => navigate(routes.UNIVERSITY)}
				/>
			</div>
		</div>
	)
}

export default Mobile
