import aliceDiCarlo from '@Assets/images/talents/alice_di_carlo.jpeg'
import digantGupta from '@Assets/images/talents/digant_gupta.jpeg'
import ilariaStocchi from '@Assets/images/talents/ilaria_stocchi.jpeg'
import jarettDewbury from '@Assets/images/talents/jarett_dewbury.jpeg'
import luciaTesta from '@Assets/images/talents/lucia_testa.jpeg'
import nikashanThavaruban from '@Assets/images/talents/nikashan_thavaruban.jpeg'
import sumitMalbari from '@Assets/images/talents/sumit_malbari.jpeg'
import timeaAszalos from '@Assets/images/talents/timea_aszalos.jpeg'
import trentStauffer from '@Assets/images/talents/trent_stauffer.jpeg'

import {ReactComponent as canada} from '@Assets/images/flags/ca.svg'
import {ReactComponent as italy} from '@Assets/images/flags/it.svg'
import {ReactComponent as usa} from '@Assets/images/flags/us.svg'

const talents = [
	{
		name: 'Jarett Dewbury',
		img: jarettDewbury,
		company: 'Watolink',
		workLogo: 'https://static.wixstatic.com/media/49633e_0401a84cb0bf4d569c44017a7c1142f5~mv2.png/v1/fill/w_356,h_200,al_c,q_85,usm_0.66_1.00_0.01/WatoLink_logo_white-01.webp',
		university: 'University of Waterloo',
		universityLogo: 'https://assets.wiraki.com/img/network-logos/618.png',
		faculty: 'Biomedical Engineering',
		country: canada,
		linkedin: 'https://www.linkedin.com/in/jarett-dewbury/'
	},
	{
		name: 'Lucia Testa',
		img: luciaTesta,
		company: 'Sapienza University',
		workLogo: 'https://cyberchallenge.it/assets/sedi/unirm1.png',
		university: 'Sapienza University of Rome',
		universityLogo: 'https://assets.wiraki.com/img/network-logos/2411.png',
		faculty: 'Data Science',
		country: italy,
		linkedin: 'https://www.linkedin.com/in/luciatesta/'
	},
	{
		name: 'Trent Stauffer',
		img: trentStauffer,
		company: 'NVIDIA',
		workLogo: 'https://logos-download.com/wp-content/uploads/2016/10/Nvidia_logo.png',
		university: 'University of Waterloo',
		universityLogo: 'https://assets.wiraki.com/img/network-logos/618.png',
		faculty: 'Computer Engineering',
		country: canada,
		linkedin: 'https://www.linkedin.com/in/trentstauffer'
	},
	{
		name: 'Alice Di Carlo',
		img: aliceDiCarlo,
		company: 'Lab of Paris 6',
		workLogo: 'https://www.lip6.fr/images/logo/LIP6.png',
		university: 'Roma Tre University',
		universityLogo: 'https://assets.wiraki.com/img/network-logos/2372.png',
		faculty: 'Computer Science and Engineering',
		country: italy,
		linkedin: 'https://www.linkedin.com/in/alicedicarlo'
	},
	{
		name: 'Nikashan Thavaruban',
		img: nikashanThavaruban,
		company: 'PlayStation',
		workLogo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/PlayStation_logo_and_wordmark.svg/2560px-PlayStation_logo_and_wordmark.svg.png',
		university: 'University of Waterloo',
		universityLogo: 'https://assets.wiraki.com/img/network-logos/618.png',
		faculty: 'Computer Science',
		country: canada,
		linkedin: 'https://www.linkedin.com/in/nikashan'
	},
	{
		name: 'Digant Gupta',
		img: digantGupta,
		company: 'Meta',
		workLogo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/7b/Meta_Platforms_Inc._logo.svg/1200px-Meta_Platforms_Inc._logo.svg.png',
		university: 'University at Buffalo',
		universityLogo: 'https://assets.wiraki.com/img/network-logos/6177.png',
		faculty: 'Computer Science',
		country: usa,
		linkedin: 'https://www.linkedin.com/in/digant-gupta/'
	},
	{
		name: 'Ilaria Stocchi',
		img: ilariaStocchi,
		company: 'Banca d\'Italia',
		workLogo: 'https://upload.wikimedia.org/wikipedia/it/thumb/5/5a/Banca_d%27Italia_logo.svg/1200px-Banca_d%27Italia_logo.svg.png',
		university: 'Roma Tre University',
		universityLogo: 'https://assets.wiraki.com/img/network-logos/2372.png',
		faculty: 'Computer Science and Engineering',
		country: italy,
		linkedin: 'https://www.linkedin.com/in/ilaria-stocchi-215201187'
	},
	/*{
		name: 'Sumit Malbari',
		img: sumitMalbari,
		company: 'Fidelity Investments',
		workLogo: 'https://blog.logomyway.com/wp-content/uploads/2021/04/fidelity-logo-PNG.png',
		university: 'Northeastern University',
		universityLogo: 'https://assets.wiraki.com/img/network-logos/5740.png',
		faculty: 'Information Systems',
		country: usa,
		linkedin: 'https://www.linkedin.com/in/sumitmalbari/'
	},*/
	{
		name: 'Timea Aszalos',
		img: timeaAszalos,
		company: 'Sapienza University',
		workLogo: 'https://cyberchallenge.it/assets/sedi/unirm1.png',
		university: 'Sapienza University of Rome',
		universityLogo: 'https://assets.wiraki.com/img/network-logos/2411.png',
		faculty: 'Data Science',
		country: italy,
		linkedin: 'https://www.linkedin.com/in/timea-aszalos-371233179/'
	}
]

export default talents
