import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import WirakiLogo from '@Assets/images/Logo.png'
import Flag from '@Icons/Flag'
import Logo from '@Icons/Logo'

const TalentCard = props => {

	return (
		<>
			<div className="card-talent d-md-none">
				<div className="card-talent-header">
					<img className="talent-img" src={props.img} alt=""/>
					<div className="talent-info">
						<a href={props.linkedin} target="_blank">
							<h4>
								<span className="fab fa-linkedin"/>
								{props.name}
							</h4>
						</a>
						<div className="talent-university">
							<img src={props.universityLogo} alt=""/>
							{props.university}
						</div>
					</div>
				</div>
				<div className="card-talent-footer">
					<div className="wiraki">
						<Logo />
						Top Rank
					</div>
					<div className="company">
						<img src={props.workLogo} alt=""/>
						<div className="company-info">
							<div className="company-name">{props.company}</div>
							{props.faculty}
						</div>
					</div>
				</div>
			</div>


			<div className="card-talent d-none d-md-block">
				<div className="talent-name">
					<Flag flag={props.country} />
					 {props.name}
				</div>
				<div className="talent-body">
					<div className="talent-image">
						<img src={props.img} alt=""/>
						<a href={props.linkedin} target="_blank">
						<div className="talent-linkedin">
							<span className="fab fa-linkedin"/>
						</div>
						</a>
					</div>
					<div className="talent-data">
						<div className="d-flex flex-row align-items-center">
							<img alt="" src={props.universityLogo} />
							<div className="d-flex flex-column justify-items-center">
								<div>{props.university}</div>
								<div className="light-gray">{props.faculty}</div>
							</div>
						</div>
						<div className="d-flex flex-row align-items-center">
							<img alt="" src={WirakiLogo} />
							<div className="d-flex flex-column justify-items-center">Top Rank</div>
						</div>
						<div className="d-flex flex-row align-items-center">
							<img alt="" src={props.workLogo} />
							<div className="d-flex flex-column justify-items-center">
								<div>{props.company}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

TalentCard.propTypes = {
	workLogo: PropTypes.string.isRequired,
	img: PropTypes.string.isRequired,
	country: PropTypes.object.isRequired,
	linkedin: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	faculty: PropTypes.string.isRequired,
	university: PropTypes.string.isRequired,
}

export default TalentCard
