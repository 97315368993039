import React from 'react'
import TalentsCarousel from '@Compounds/TalentsCarousel'
import { Trans } from 'react-i18next'
import routes from '@Routes/routeConfig'
import { useNavigate } from 'react-router-dom'

const Talents = props => {

	const navigate = useNavigate()

	return (
		<div className="talents">
			<div className="title">
				<Trans i18nKey="stepWelcome.desktop.talents.title" />
			</div>
			<div style={{ marginBottom: '100px' }}>
				<TalentsCarousel />
			</div>
			<div className="text-center">
				<button
					className="btn btn-lg btn-primary btn-rounded fw-bold"
					onClick={() => navigate(routes.UNIVERSITY)}
				>
					<Trans i18nKey="stepWelcome.desktop.talents.button" />
				</button>
			</div>
		</div>
	)
}

export default Talents
