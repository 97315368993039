import React from 'react'
import PropTypes from 'prop-types'
import StickyButton from '@Atoms/StickyButton'

const LayoutFormMobile = props => {

	const { children, stickyButtonLabel, buttonNextActive } = props

	if (window.innerWidth >= 768) return null

	return (
		<div className="container-form d-md-none" id="layout-mobile">
			{children}
			{stickyButtonLabel && (
				<StickyButton buttonClassName={buttonNextActive ? '' : 'btn-inactive'} className="d-md-none" buttonText={stickyButtonLabel}/>
			)}
		</div>
	)
}
LayoutFormMobile.defaultProps = {
	buttonNextActive: true
}

LayoutFormMobile.propTypes = {
	children: PropTypes.any.isRequired,
	stickyButtonLabel: PropTypes.string,
	buttonNextActive: PropTypes.bool,
}

export default LayoutFormMobile
