import React from 'react'
import talents from '@Common/data/talents'
import TalentCard from '@Atoms/talentCard'
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import PropTypes from 'prop-types'

const TalentsCarousel = props => {

	const {
		dots,
		arrows,
		infinite,
		speed,
		autoplay,
		autoplaySpeed,
		slidesToShow,
		slidesToScroll,
		className
	} = props

	return (
		<Slider
			dots={dots}
			arrows={arrows}
			infinite={infinite}
			speed={speed}
			autoplay={autoplay}
			autoplaySpeed={autoplaySpeed}
			slidesToShow={slidesToShow}
			slidesToScroll={slidesToScroll}
			responsive={[
				{ breakpoint: 425, settings: { slidesToShow: 1 } },
				{ breakpoint: 1440, settings: { slidesToShow: 2 } }
			]}
			className={`${className} d-flex flex-row justify-content-evenly flex-wrap`}
		>
			{talents.map((talent, k) => (
				<TalentCard
					key={k}
					company={talent.company}
					workLogo={talent.workLogo}
					img={talent.img}
					country={talent.country}
					linkedin={talent.linkedin}
					name={talent.name}
					faculty={talent.faculty}
					university={talent.university}
					universityLogo={talent.universityLogo}
				/>
			))}
		</Slider>
	)
}

TalentsCarousel.defaultProps = {
	dots: false,
	arrow: false,
	infinite: true,
	speed: 800,
	autoplay: true,
	autoplaySpeed: 3000,
	slidesToShow: 3,
	slidesToScroll: 1,
}

TalentsCarousel.propTypes = {
	dots: PropTypes.bool,
	arrows: PropTypes.bool,
	infinite: PropTypes.bool,
	speed: PropTypes.number,
	autoplay: PropTypes.bool,
	autoplaySpeed: PropTypes.number,
	slidesToShow: PropTypes.number,
	slidesToScroll: PropTypes.number,
	className: PropTypes.string,
}

export default TalentsCarousel
