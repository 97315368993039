/* FORM VOTE */
export const LOAD_LOCAL_STORAGE_STATE = 'LOAD_LOCAL_STORAGE_STATE'

export const CHECK_REFERRAL = 'CHECK_REFERRAL'
export const CHECK_COUNTDOWN = 'CHECK_COUNTDOWN'

export const SET_REFERRAL_VALID = 'SET_REFERRAL_VALID'

export const SUBMIT_UNIVERSITY = 'SUBMIT_UNIVERSITY'
export const SUBMIT_VOTES = 'SUBMIT_VOTES'
export const SUBMIT_REDEEM_REWARD = 'SUBMIT_REDEEM_REWARD'
export const REDEEM_REWARD_SUBMITTED = 'REDEEM_REWARD_SUBMITTED'

export const SAVE_UNIVERSITY_DATA = 'SAVE_UNIVERSITY_DATA'
export const SAVE_FACULTY_DATA = 'SAVE_FACULTY_DATA'

export const LOAD_DATA_UNIVERSITY = 'LOAD_DATA_UNIVERSITY'
export const LOAD_DATA_FACULTY = 'LOAD_DATA_FACULTY'

export const LOAD_LIST_PARTICIPANTS_UNIVERSITY = 'LOAD_LIST_PARTICIPANTS_UNIVERSITY'
export const SAVE_LIST_PARTICIPANTS_UNIVERSITY = 'SAVE_LIST_PARTICIPANTS_UNIVERSITY'
export const LOAD_LIST_PARTICIPANTS_FACULTY = 'LOAD_LIST_PARTICIPANTS_FACULTY'
export const SAVE_LIST_PARTICIPANTS_FACULTY = 'SAVE_LIST_PARTICIPANTS_FACULTY'

export const SAVE_ATTENDING_YEAR = 'SAVE_ATTENDING_YEAR'

export const LOAD_GLOBAL_STATS = 'LOAD_GLOBAL_STATS'
export const SAVE_GLOBAL_STATS = 'SAVE_GLOBAL_STATS'

export const REDIRECTED = 'REDIRECTED'
