import React from 'react'

import routes from '@Routes/routeConfig'

// Profile
// Authentication related pages
import StepUniversity from '@Pages/StepUniversity'
import StepVotes from '@Pages/StepVotes'
import StepReward from '@Pages/StepReward'
import StepWelcome from '@Pages/StepWelcome'

import NonAuthLayout from '@Components/NonAuthLayout'

const authProtectedRoutes = [
	// this route should be at the end of all other routes
	// eslint-disable-next-line react/display-name
	// { path: routes.DASHBOARD, component: Dashboard },
	/*{ path: routes.HOME, exact: true, component: () => <Redirect to="/dashboard"/> },*/
]

const publicRoutes = [
	{ layout: NonAuthLayout, path: routes.HOME, component: StepWelcome, exact: true },
	{ layout: NonAuthLayout, path: routes.UNIVERSITY, component: StepUniversity, exact: true },
	{ layout: NonAuthLayout, path: routes.VOTE, component: StepVotes, exact: true },
	{ layout: NonAuthLayout, path: routes.REDEEM, component: StepReward, exact: true },
	//{ component: Page404 },
]

export { publicRoutes, authProtectedRoutes }
