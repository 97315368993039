import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "@Store/auth/register/saga"
import AuthSaga from "@Store/auth/login/saga"
import ForgetSaga from "@Store/auth/forgetpwd/saga"
import ProfileSaga from "@Store/auth/profile/saga"

import SystemSaga from "@Store/system/saga"

import FormVoteSaga from "@Store/formVote/saga"
import TemplateSaga from "@Store/template/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),

    fork(SystemSaga),

    fork(FormVoteSaga),
    fork(TemplateSaga),
  ])
}
