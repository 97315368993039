import { combineReducers } from "redux"

// Authentication
import Login from "@Store/auth/login/reducer"
import Account from "@Store/auth/register/reducer"
import ForgetPassword from "@Store/auth/forgetpwd/reducer"
import Profile from "@Store/auth/profile/reducer"

import System from "@Store/system/reducer"

import FormVote from "@Store/formVote/reducer"
import Template from "@Store/template/reducer"

const rootReducer = combineReducers({
  // public
  Login,
  Account,
  ForgetPassword,
  Profile,
  System,

  FormVote,
  Template,
})

export default rootReducer
