import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

// Import Routes all
import { publicRoutes } from '@Routes/index'

// Import all middleware
// Import scss
import '@Assets/scss/theme.scss'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { loadLocalStorageState, redirected } from '@Store/formVote/actions'

const App = () => {

	const currentPath = window.location.pathname

	const stepsData = useSelector(state => state.FormVote)
	const redirect = useSelector(state => state.FormVote.redirect)

	const dispatch = useDispatch()
	const navigate = useNavigate()

	// At beginning, load local storage
	useEffect(() => {
		dispatch(loadLocalStorageState())
	}, [])

	// Update on change stepsData and redirect if cannot stay in this step
	useEffect(() => {
		if (stepsData.steps && stepsData.localStorageLoaded) {
			Object.keys(stepsData.steps).map(k => {
				if (stepsData.steps[k].url === currentPath && !stepsData.steps[k].isAccessible) navigate(stepsData.steps[k - 1].url)
			})
		}
	}, [stepsData])

	useEffect(() => {
		if (redirect) {
			if (redirect !== window.location.pathname) {
				navigate(redirect)
			}
			dispatch(redirected())
		}
	}, [redirect])

	return (
		<React.Fragment>
			<Routes>
				{publicRoutes.map((route, idx) => {
					const Layout = route.layout
					const Component = route.component
					return (
						<Route
							key={idx}
							path={route.path}
							exact={route.exact}
							element={
								<Layout>
									<Component/>
								</Layout>
							}
						/>
					)
				})}
			</Routes>
		</React.Fragment>
	)
}

App.propTypes = {
	layout: PropTypes.any,
}

export default App
