import React from 'react'
import Cards from '@Pages/StepWelcome/Desktop/HowItWorks/Cards'
import { Trans } from 'react-i18next'

const HowItWorks = props => {

	return (
		<div className="how-it-works w-100">
			<div className="container">
				<h2><Trans i18nKey="stepWelcome.desktop.howItWorks.title" /></h2>
				<Cards />
			</div>
		</div>
	)
}

export default HowItWorks
