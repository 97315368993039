import {ReactComponent as CreditCardIcon} from '@Assets/images/instagram.svg'
import PropTypes from 'prop-types'

const Instagram = props => {

	return (
		<CreditCardIcon
			className={props.className}
			style={props.style}
			alt="" />
	)
}

Instagram.propTypes = {
	style: PropTypes.object,
	className: PropTypes.string,
}

export default Instagram
