export const ERROR_MESSAGE_GENERIC = 'ERROR_MESSAGE_GENERIC'
export const ERROR_MESSAGE_MISSING_UNIVERSITY = 'ERROR_MESSAGE_MISSING_UNIVERSITY'
export const ERROR_MESSAGE_MISSING_FACULTY = 'ERROR_MESSAGE_MISSING_FACULTY'
export const ERROR_MESSAGE_MISSING_FIRST_VOTE = 'ERROR_MESSAGE_MISSING_FIRST_VOTE'
export const ERROR_MESSAGE_MISSING_SECOND_VOTE = 'ERROR_MESSAGE_MISSING_SECOND_VOTE'
export const ERROR_MESSAGE_MISSING_THIRD_VOTE = 'ERROR_MESSAGE_MISSING_THIRD_VOTE'
export const ERROR_MESSAGE_MISSING_UNIVERSITY_EMAIL = 'ERROR_MESSAGE_MISSING_UNIVERSITY_EMAIL'
export const ERROR_MESSAGE_MISSING_NAME = 'ERROR_MESSAGE_MISSING_NAME'
export const ERROR_MESSAGE_MISSING_ATTENDING_YEAR = 'ERROR_MESSAGE_MISSING_ATTENDING_YEAR'
export const ERROR_MESSAGE_MISSING_DEGREE_TYPE = 'ERROR_MESSAGE_MISSING_DEGREE_TYPE'
