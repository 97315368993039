import React, { useState } from 'react'
import { Col, Row } from 'reactstrap'
import FastForwardRoundedIcon from '@mui/icons-material/FastForwardRounded'
import { Trans } from 'react-i18next'
import ReactCardFlip from 'react-card-flip'
import wordBoost from '@Assets/images/wordBoost.svg'

const BoxHelloFuture = props => {

	const [flipped, setFlipped] = useState(false)

	return (
		<ReactCardFlip isFlipped={flipped} flipDirection="horizontal">

			<div className="clickable box box-hello-future box-secondary d-flex align-items-center" onClick={() => setFlipped(!flipped)}>
				<FastForwardRoundedIcon/>
				<FastForwardRoundedIcon/>
				<Trans i18nKey="stepWelcome.desktop.slider.helloFuture"/>
			</div>
			<div className="clickable box box-hello-future flipped box-secondary" onClick={() => setFlipped(!flipped)}>
				<p className="mb-0">
					<Trans
						i18nKey="stepWelcome.desktop.slider.helloFutureFlipped"
						components={{
							img: <img className="wordBoost" src={wordBoost} alt=""/>
						}}
					/>
				</p>
			</div>
		</ReactCardFlip>
	)
}

export default BoxHelloFuture
