import React, { useState } from 'react'
import { loadDataFaculty, saveFacultyData } from '@Store/formVote/actions'
import { Trans } from 'react-i18next'
import Highlighter from 'react-highlight-words'
import AsyncSelect from 'react-select/async/dist/react-select.esm'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import InputBox from '@Compounds/InputBox'
import { selectAutocompleteStyle, formatAutocomplete } from '@Pages/StepUniversity/dropdownTools'

const SelectUniversity = props => {

	const { className, faculty, submitted } = props

	const getFacultyList = (input, callback) => {
		axios.get(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/public/faculties?fields=id,name&term=${input}&network=${props.universityId}`)
			.then(({ data }) => {
				callback(data.map((item) => ({ ...item, label: item.name })))
			})
	}

	const dispatch = useDispatch()

	const [useAutocompleteStyle, setUseAutocompleteStyle] = useState(false)
	const [isTouched, setIsTouched] = useState(false)

	const isInvalid = (submitted && !faculty) || (isTouched && !faculty)

	return (
		<InputBox
			className={className}
			errors={submitted && !faculty || isTouched && !faculty}
			touched={isTouched || faculty}
		>
			<AsyncSelect
				value={faculty}
				onChange={(e) => {
					dispatch(loadDataFaculty(e.id))
				}}
				isMulti={false}
				onInputChange={(e, { action }) => {
					if (action === 'input-change') {
						dispatch(saveFacultyData(null))
					} else if (action === 'menu-close') {
						setUseAutocompleteStyle(false)
						document.activeElement.blur()
					}
				}}
				className={`select-box no-pipe-dropdown w-100 ${!faculty || 'valid'} ${isInvalid ? 'invalid' : ''}`}
				loadOptions={getFacultyList}
				classNamePrefix="autocomplete"
				placeholder={<Trans i18nKey="stepUniversity.inputFaculty.placeholder"/>}
				styles={selectAutocompleteStyle(useAutocompleteStyle)}
				onKeyDown={(e) => {
					if (['Backspace', 'Delete'].indexOf(e.code) > -1 && e.target.value === '') {
						dispatch(saveFacultyData(null))
					}
				}}
				onBlur={() => {
					setIsTouched(true)
				}}
				onFocus={(e) => {
					setIsTouched(false)
					// Scroll to element
					const positionY = e.target.getBoundingClientRect().top + +document.documentElement.scrollTop
					window.scrollTo(0, positionY - 180)
					setUseAutocompleteStyle(true)
				}}
				formatOptionLabel={({ label }, { inputValue }) => formatAutocomplete(inputValue, label)}
				noOptionsMessage={() => <Trans i18nKey="stepUniversity.inputFaculty.noOptions"/>}
			/>
		</InputBox>
	)
}

export default SelectUniversity
