import React, { useEffect } from 'react'
//Import Image
import MetaTags from 'react-meta-tags'
import 'react-phone-input-2/lib/style.css'
import Topbar from '@Components/Layout/Topbar'
import ModalReferral from '@Modals/ModalReferral'
import * as queryString from 'query-string'
import { checkCountdown, checkReferral } from '@Store/formVote/actions'
import { useDispatch } from 'react-redux'
import Mobile from '@Pages/StepWelcome/Mobile'
import Desktop from '@Pages/StepWelcome/Desktop'

const StepWelcome = props => {

	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(checkReferral(queryString.parse(location.search).t))
		dispatch(checkCountdown())
	}, [])

	return (
		<React.Fragment>
			<MetaTags>
				<title>Vote | Wiraki</title>
			</MetaTags>
			<Topbar currentTab="welcome"/>
			<Mobile/>
			<Desktop/>
			<ModalReferral referral={{ fullName: 'carlo' }}/>
		</React.Fragment>
	)
}

export default StepWelcome
