import React, { useState } from 'react'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import GradYears from '@Pages/StepReward/GradYears'
import { scrollToElement } from '@Helpers/global'

const SelectYear = props => {

	const {
		submitted,

		universityCycleTouched,
		setUniversityCycleTouched,
		universityCycle,
		setUniversityCycle,

		attendingYearTouched,
		setAttendingYearTouched,
		attendingYear,
		setAttendingYear,
		countryCode
	} = props

	const { t } = useTranslation()

	const gradYears = GradYears(t, countryCode)

	return (
		<div className="d-flex" style={{ flexGrow: 1 }}>
			<Select
				value={universityCycle}
				options={Object.keys(gradYears).map(k => gradYears[k])}
				onFocus={(e) => {
					setUniversityCycleTouched(false)
					scrollToElement(e)
				}}
				onBlur={() => {
					setUniversityCycleTouched(true)
				}}
				onChange={(e) => {
					setUniversityCycle(e)
					setAttendingYear(null)
				}}
				classNamePrefix="autocomplete"
				placeholder={t('stepRedeem.inputYear.placeholder')}
				isSearchable={false}
				className={`select-box inline-select no-pipe-dropdown ${(submitted && !universityCycle) || (!universityCycle && universityCycleTouched) ? 'invalid' : ''}`}
				isRequired={true}
			/>
			<span className="separator"/>
			<Select
				value={attendingYear}
				options={gradYears[universityCycle?.value]?.years || []}
				onFocus={(e) => {
					setAttendingYearTouched(false)
					scrollToElement(e)
				}}
				onBlur={() => {
					setAttendingYearTouched(true)
				}}
				onChange={(e) => setAttendingYear(e)}
				classNamePrefix="autocomplete"
				placeholder={t('stepRedeem.inputYear.placeholderYear')}
				isSearchable={false}
				className={`select-box inline-select no-pipe-dropdown ${(submitted && !attendingYear) || (!attendingYear && attendingYearTouched) ? 'invalid' : ''}`}
				isRequired={true}
				isDisabled={!universityCycle}
			/>
		</div>
	)
}

SelectYear.propTypes = {
	universityCycleTouched: PropTypes.bool.isRequired,
	setUniversityCycleTouched: PropTypes.func.isRequired,
	universityCycle: PropTypes.object,
	setUniversityCycle: PropTypes.func.isRequired,


	attendingYearTouched: PropTypes.bool.isRequired,
	setAttendingYearTouched: PropTypes.func.isRequired,
	attendingYear: PropTypes.object,
	setAttendingYear: PropTypes.func.isRequired,

	countryCode: PropTypes.string,
}

export default SelectYear
