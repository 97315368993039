import React, { useEffect, useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { Trans } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Countdown from '@Atoms/countdown'
import { toggleModal } from '@Store/template/actions'
import { MODAL_COUNTDOWN } from '@Modals/index'
import img from '@Assets/images/img_clock.png'

const ModalCountdown = () => {

	const dispatch = useDispatch()

	const [isOpen, setOpen] = useState(false)

	const countdown = useSelector(state => state.FormVote.countdown)
	const dataModal = useSelector(state => state.Template.modal)
	const referral = useSelector(state => state.FormVote.referral)

	useEffect(() => {
		setOpen(!!dataModal[MODAL_COUNTDOWN]?.status)
	}, [dataModal])

	let referralName = 'Wiraki'
	if(referral && referral.fullName && referral.fullName !== '') {
		referralName = referral.fullName
	}

	return (
		<React.Fragment>
			<Modal
				centered
				size="md"
				isOpen={isOpen}
				toggle={() => dispatch(toggleModal(MODAL_COUNTDOWN, false))}
				className="m-auto"
				contentClassName="border-0"
				id="modal-countdown"
			>
				<ModalBody className="text-center">
					<div className="">
						<img src={img} alt=""/>
						<h3 className="modal-title"><Trans i18nKey="modalCountdown.title"/></h3>
						<p className="modal-text">
							<Trans
								i18nKey="modalCountdown.text"
								values={{ referral: referralName }}

							/>
						</p>
					</div>
					<Countdown
						date={countdown} className="mt-3 mb-4"
					/>
					<button
						onClick={() => dispatch(toggleModal(MODAL_COUNTDOWN, false))}
						type="button"
						className="btn btn-link-secondary btn-lg"
						data-dismiss="modal"
					>
						<Trans i18nKey="modalCountdown.button"/>
					</button>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}
export default ModalCountdown
