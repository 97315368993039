import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import "./i18n"
import { Provider } from "react-redux"
import store from "./store"
import smartlookClient from 'smartlook-client'
import MetaTags from 'react-meta-tags'

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    /* Google Analytics */
    import('googleAnalytics.js')

    /* Smartlook */
    smartlookClient.init('a4522cf0f35b52eccec5dc9f3ad2c53599aff890')
}


const app = (
  <Provider store={store}>
      <MetaTags>
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      </MetaTags>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()
