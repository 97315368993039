import React, { useEffect, useState } from 'react'
//Import Image
import 'react-phone-input-2/lib/style.css'
import { Trans, useTranslation } from 'react-i18next'

import { useForm } from 'react-hook-form'
import Input from '@Atoms/Input'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { submitRedeemReward } from '@Store/formVote/actions'
import InputBox from '@Compounds/InputBox'
import SelectYear from '@Pages/StepReward/SelectYear'
import GradYears from '@Pages/StepReward/GradYears'
import LayoutForm from '@Components/Layout/LayoutForm'
import LayoutFormMobile from '@Components/Layout/LayoutFormMobile'
import LayoutFormDesktop from '@Components/Layout/LayoutFormDesktop'
import { Col, Row } from 'reactstrap'
import EasyTips from '@Compounds/EasyTips'
import CancelIcon from '@mui/icons-material/Cancel'
import CreditCard from '@Icons/CreditCard'
import Amazon from '@Icons/Amazon'

const StepReward = props => {

	const { t } = useTranslation()
	const dispatch = useDispatch()
	const currentTab = 'redeemReward'

	const { university } = useSelector(state => state.FormVote.dataCheckVote)
	const dataRedeemReward = useSelector(state => state.FormVote.dataRedeemReward)
	const submitError = useSelector(state => state.FormVote.submitError)

	const [submitted, setSubmitted] = useState(false)
	const [errorMessage, setErrorMessage] = useState()

	// University cycle
	const [universityCycle, setUniversityCycle] = useState()
	const [universityCycleTouched, setUniversityCycleTouched] = useState(false)

	// Attending year
	const [attendingYear, setAttendingYear] = useState()
	const [attendingYearTouched, setAttendingYearTouched] = useState(false)

	const tipsList = [
		{
			text: <Trans i18nKey="easyTips.4"/>, icon: <div className="position-relative">
				<CreditCard className="icon"/>
				<CancelIcon
					className="text-primary icon-cancel"
					style={{
						backgroundColor: 'white',
						borderRadius: '50%',
						top: -4,
						right: -7
					}}
				/>
			</div>
		},
		{ text: <Trans i18nKey="easyTips.5"/>, icon: <Amazon className="text-primary icon"/> },
	]

	const onSubmit = (data) => {

		setSubmitted(true)
		setAttendingYearTouched(true)
		setUniversityCycleTouched(true)
		setErrorMessage(null)
		if (!attendingYear || !universityCycle) return
		dispatch(submitRedeemReward({
			...data,
			attendingYear: attendingYear
		}))
	}

	useEffect(() => {
		if (submitError?.errorMessage) {
			setErrorMessage(submitError.errorMessage)
			setSubmitted(false)
		}
	}, [submitError])

	const {
		register,
		handleSubmit,
		formState: { errors },
		formState,
		setValue,
		watch,
	} = useForm({
		mode: 'onBlur',
		reValidateMode: 'onChange',
	})

	useEffect(() => {
		setValue('name', dataRedeemReward.name)
		setValue('phone', dataRedeemReward.phone)
		setValue('universityEmail', dataRedeemReward.universityEmail)

		// Set universityCycle
		if (dataRedeemReward.attendingYear) {
			const gradYears = GradYears(t, university.countryCode)
			Object.keys(gradYears).map(k => {
				Object.keys(gradYears[k].years).map(k1 => {
					if (
						gradYears[k].years[k1].obj.degreeType === dataRedeemReward.attendingYear.obj.degreeType
						&&
						gradYears[k].years[k1].obj.attendingYear === dataRedeemReward.attendingYear.obj.attendingYear
					) {
						setUniversityCycle(gradYears[k])
						setUniversityCycleTouched(true)
						setAttendingYear(gradYears[k].years[k1])
						setAttendingYearTouched(true)
					}
				})
			})
		}
	}, [university, dataRedeemReward])

	if (!university) return null

	const inputs = {
		name: {
			label: t('stepRedeem.inputFullName.label'),
			errorMessage: t('stepRedeem.inputFullName.error'),
			infoMessage: null,
			registerOptions: { required: true, validate: async (value) => { return /^.+\s.+$/.test(value)} }
		},
		universityEmail: {
			label: t('stepRedeem.inputUniversityEmail.label'),
			errorMessage: t('stepRedeem.inputUniversityEmail.error'),
			infoMessage: t('stepRedeem.inputUniversityEmail.infoMessage'),
			// Email validation has been moved backend to keep track of pattern exceptions
			//registerOptions: getUniversityEmailPattern(university)
			registerOptions: { required: true }
		},
		phone: {
			label: t('stepRedeem.inputPhoneNumber.label'),
			errorMessage: t('stepRedeem.inputPhoneNumber.error'),
			infoMessage: t('stepRedeem.inputPhoneNumber.error'),
			registerOptions: { required: false, validate: async (value) => { return /^\s*[+]?[\s\-0-9]{5,}$|^$/.test(value)} }
		}
	}

	const buttonNextActive = !!watch('name') && !!watch('universityEmail') && !!universityCycle && !!attendingYear

	return (
		<LayoutForm
			currentTab={currentTab}
			handleSubmit={handleSubmit(onSubmit)}
		>
			<LayoutFormMobile
				stickyButtonLabel={t('stepRedeem.buttonNext')}
				buttonNextActive={buttonNextActive}
				buttonNextDisabled={submitted}
			>
				<div className="container-form">
					<div className="title fw-bold"><Trans i18nKey="stepRedeem.title"/></div>
					<div className="subtitle fw-light mb-3"><Trans i18nKey="stepRedeem.subtitleMobile"/></div>
					{Object.keys(inputs).map((input, k) => (
						<InputBox
							key={k}
							label={inputs[input].label}
							errors={errors[input]}
							touched={
								(inputs[input].registerOptions.required && formState.touchedFields[input])
								||
								(!inputs[input].registerOptions.required && formState.touchedFields[input] && watch()[input] !== '')
								||
								!!watch()[input]
							}
						>
							<Input
								required
								register={register}
								name={input}
								autocompleteWith={input}
								errors={errors[input]}
								touched={formState.touchedFields[input] || !!watch()[input]}
								inputInfoMessage={inputs[input].infoMessage}
								inputErrorMessage={inputs[input].errorMessage}
								registerOptions={inputs[input].registerOptions}
								type="text"/>
						</InputBox>
					))}
					<InputBox
						className="container-select container-select-attending-year"
						label={t('stepRedeem.inputYear.label')}
						errors={(attendingYearTouched && !attendingYear) || (universityCycleTouched && !universityCycle)}
						touched={attendingYearTouched || universityCycleTouched}
						isValid={attendingYearTouched && attendingYear && universityCycleTouched && universityCycle}
						withErrorMessage={false}
					>
						<SelectYear
							submitted={submitted}

							attendingYearTouched={attendingYearTouched}
							setAttendingYearTouched={setAttendingYearTouched}
							attendingYear={attendingYear}
							setAttendingYear={setAttendingYear}

							universityCycleTouched={universityCycleTouched}
							setUniversityCycleTouched={setUniversityCycleTouched}
							universityCycle={universityCycle}
							setUniversityCycle={setUniversityCycle}

							countryCode={university.countryCode}
						/>
					</InputBox>
					{errorMessage && (
						<div className="error-message">
							{t(`submitErrorCodes.${errorMessage}`)}
						</div>
					)}
					<EasyTips tipsList={tipsList}/>
					<div style={{ fontSize: '10px' }}>
						<Trans
							i18nKey="termsConditions"
							components={{
								terms: <a className="text-secondary" target="_blank" href={`${process.env.REACT_APP_WIRAKI_WEBSITE}/terms-and-conditions`}/>,
								privacy: <a className="text-secondary" target="_blank" href={`${process.env.REACT_APP_WIRAKI_WEBSITE}/privacy`}/>
							}}
						/>
					</div>
				</div>
			</LayoutFormMobile>
			<LayoutFormDesktop
				currentTab={currentTab}
				buttonPreviousText={t('stepRedeem.buttonBack')}
				buttonNextText={t('stepRedeem.buttonNext')}
				buttonNextActive={buttonNextActive}
				buttonNextFilled
				buttonNextWithoutIcon
				buttonNextDisabled={submitted}
				title={(
					<>
						<div className="title fw-bold"><Trans i18nKey="stepRedeem.titleDesktop"/></div>
						<div className="subtitle fw-light mb-3"><Trans i18nKey="stepRedeem.subtitleDesktop"/></div>
					</>
				)}
				footer={(
					<div className="text-center mt-2" style={{ fontSize: '10px' }}>
						<Trans
							i18nKey="termsConditions"
							components={{
								terms: <a className="text-secondary" target="_blank" href={`${process.env.REACT_APP_WIRAKI_WEBSITE}/terms-and-conditions`}/>,
								privacy: <a className="text-secondary" target="_blank" href={`${process.env.REACT_APP_WIRAKI_WEBSITE}/privacy`}/>
							}}
						/>
					</div>
				)}
			>
				<Row className="box-content-card h-100">
					<Col md={6} className="border-right">
						<div className="container-form">
							{Object.keys(inputs).map((input, k) => (
								<InputBox
									key={k}
									label={inputs[input].label}
									errors={errors[input]}
									touched={formState.touchedFields[input] || !!watch()[input]}
								>
									<Input
										required
										register={register}
										name={input}
										autocompleteWith={input}
										errors={errors[input]}
										touched={formState.touchedFields[input] || !!watch()[input]}
										inputInfoMessage={inputs[input].infoMessage}
										inputErrorMessage={inputs[input].errorMessage}
										registerOptions={inputs[input].registerOptions}
										type="text"/>
								</InputBox>
							))}
							<InputBox
								className="container-select container-select-attending-year"
								label={t('stepRedeem.inputYear.label')}
								errors={(attendingYearTouched && !attendingYear) || (universityCycleTouched && !universityCycle)}
								touched={attendingYearTouched || universityCycleTouched}
								isValid={attendingYearTouched && attendingYear && universityCycleTouched && universityCycle}
								withErrorMessage={false}
							>
								<SelectYear
									submitted={submitted}

									attendingYearTouched={attendingYearTouched}
									setAttendingYearTouched={setAttendingYearTouched}
									attendingYear={attendingYear}
									setAttendingYear={setAttendingYear}

									universityCycleTouched={universityCycleTouched}
									setUniversityCycleTouched={setUniversityCycleTouched}
									universityCycle={universityCycle}
									setUniversityCycle={setUniversityCycle}

									countryCode={university.countryCode}
								/>
							</InputBox>
							{errorMessage && (
								<div className="error-message">
									{t(`submitErrorCodes.${errorMessage}`)}
								</div>
							)}
						</div>
					</Col>
					<Col md={6}>
						<EasyTips tipsList={tipsList}/>
					</Col>
				</Row>
			</LayoutFormDesktop>
		</LayoutForm>
	)
}

const buildUniversityPattern = domain => {
	domain = domain.replaceAll('\.', '\\.')
	domain = domain.replaceAll('\-', '\\-')
	return domain
}

const getUniversityEmailPattern = university => {
	let universityPattern
	if (process.env.REACT_APP_ENVIRONMENT !== 'production' && university.domains.length > 0) {
		// DEVELOPMENT FEATURE
		university.domains.push('wiraki.com')
	}
	university.domains = _.uniq(university.domains)
	universityPattern = `(?:${university.domains.map(d => buildUniversityPattern(d)).join('|')})`

	let universityPatternValidate = { required: true, validate: async (value) => { return (new RegExp('^.*' + universityPattern + '$')).test(value)} }

	/* If university has no email, let's open it to any email */
	if (university.domains.length === 0) {
		universityPatternValidate = { required: true }
	}
	return universityPatternValidate
}

export default StepReward
