import React from 'react'
import { Trans } from 'react-i18next'
import { formatCurrency } from '@Helpers/number_helper'
import routes from '@Routes/routeConfig'
import { useNavigate } from 'react-router-dom'

const EasyTips = props => {

	const navigate = useNavigate()

	const easyTips = [
		{ icon: '💰', text: <Trans i18nKey="easyTips.desktop.1" values={{ amount: formatCurrency(1000) }}/> },
		{ icon: '⏱', text: <Trans i18nKey="easyTips.desktop.2"/> },
		{ icon: '🌍', text: <Trans i18nKey="easyTips.desktop.3"/> },
		{ icon: '🤝', text: <Trans i18nKey="easyTips.desktop.4"/> },
	]

	return (
		<div className="container easy-tips">
			<div className="text-center">
				<button
					className="btn btn-lg btn-primary btn-rounded fw-bold"
					onClick={() => navigate(routes.UNIVERSITY)}
				><Trans i18nKey="stepWelcome.desktop.easyTips.button" /></button>
			</div>
			<div className="title"><Trans i18nKey="easyTips.title"/></div>
			<div className="easy-tips-container">
				{easyTips.map((tip, k) => (
					<div className="easy-tips-item" key={k}>
						<div className="easy-tips-card">
							<div className="icon">{tip.icon}</div>
							<div className="text">{tip.text}</div>
						</div>
					</div>
				))}
			</div>
		</div>
	)
}

export default EasyTips
