import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import 'swiper/swiper.min.css'

import universities from '@Data/universities'
import { Autoplay } from 'swiper'

const CarouselCompanies = props => {

	return (
		<Swiper
			slidesPerView={8}
			spaceBetween={15}
			centeredSlides={false}
			loop={true}
			speed={1000}
			direction={'horizontal'}
			pagination={{
				clickable: false
			}}
			autoplay={{
				delay: 1,
				disableOnInteraction: false,
			}}
			modules={[Autoplay]}
			className="swiper-smooth universities"
		>
			{universities.map((c, k) => (
				<SwiperSlide key={k}>
					<img src={c} alt=""/>
				</SwiperSlide>
			))}
		</Swiper>

	)
}

export default CarouselCompanies
