import React from 'react'
import PropTypes from 'prop-types'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'

const InputBox = props => {

	const { touched, errors, isValid } = props

	let showValidIcon = isValid
	if (typeof showValidIcon === 'undefined') {
		showValidIcon = !errors && touched
	}

	return (
		<div className={`container-input ${props.className}`}>
			{props.label && (
				<label className={!errors ? '' : 'text-danger'}>{props.label}</label>
			)}
			<div className={`input ${props.inputClass} ${props.withErrorMessage ? '' : ' no-error-message'}`}>
				{props.children}
				<div className="input-icon-outside">
					{showValidIcon ? (
						<CheckCircleRoundedIcon className="input-icon text-primary"/>
					) : errors ? (
						<ErrorRoundedIcon className="input-icon text-danger"/>
					) : (
						<RadioButtonUncheckedIcon className="input-icon"/>
					)}
				</div>
			</div>
		</div>
	)
}

InputBox.defaultProps = {
	className: '',
	inputClass: '',
	enableEyePassword: true,
	withErrorMessage: true,
}

InputBox.propTypes = {
	className: PropTypes.string,
	inputClass: PropTypes.string,
	label: PropTypes.string,
	children: PropTypes.any.isRequired,
	errors: PropTypes.any,
	withErrorMessage: PropTypes.bool,
	touched: PropTypes.any,
	isValid: PropTypes.bool,
}

export default InputBox
