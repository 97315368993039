import { put } from 'redux-saga/effects'
import { errorAxiosCall, errorAxiosCall400, errorAxiosCall500, genericError } from '@Store/system/actions'

function handleError (error) {
	if (error.response && error.response.status) {
		if (error.response.status >= 400 && error.response.status < 500) {
			put(errorAxiosCall400(error.message))
		} else if (error.response.status >= 500 && error.response.status < 600) {
			put(errorAxiosCall500(error.message))
		} else {
			put(errorAxiosCall(error.message))
		}
	} else {
		put(genericError(error.message))
	}
}

export {
	handleError,
}
