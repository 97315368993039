import React from 'react'
import { Trans } from 'react-i18next'
import { Col, Row } from 'reactstrap'

const Cards = props => {

	const infos = [
		{
			title: <Trans i18nKey="stepWelcome.desktop.howItWorks_1.title"/>,
			text: <Trans i18nKey="stepWelcome.desktop.howItWorks_1.text"/>,
			img: <span style={{fontSize: '34px'}}>👩🏼👨🏻👩🏽</span>
		},
		{
			title: <Trans i18nKey="stepWelcome.desktop.howItWorks_2.title"/>,
			text: <Trans i18nKey="stepWelcome.desktop.howItWorks_2.text"/>,
			img: <span style={{fontSize: '22px'}}>👩🏼<br/>👨🏻👩🏽<br/>👩🏼👨🏻👩🏽</span>
		},
		{
			title: <Trans i18nKey="stepWelcome.desktop.howItWorks_3.title"/>,
			text: <Trans i18nKey="stepWelcome.desktop.howItWorks_3.text"/>,
			img: <span style={{ position: 'relative', fontSize: '42px', marginBottom: '14px' }}>👩🏼<span style={{ fontSize: '42px', position: 'absolute', left: '20px', top: '25px' }}>🔍</span></span>
		},
		{
			title: <Trans i18nKey="stepWelcome.desktop.howItWorks_4.title"/>,
			text: <Trans i18nKey="stepWelcome.desktop.howItWorks_4.text"/>,
			img: <span style={{ fontSize: '56px' }}>💰</span>
		},
	]

	return (
		<div className="d-flex container-howItWorks-card">
			{infos.map((info, k) => (
				<div key={k} className="howItWorks-item">
					<div className="number">0{(k+1)}</div>
					<div className="howItWorks-card">
						<div className="title">{info.title}</div>
						<div className="icon">{info.img}</div>
						<div className="text">{info.text}</div>
					</div>
				</div>
			))}
		</div>
	)
}

export default Cards
