import React from 'react'
import { Col, Row } from 'reactstrap'
import { Trans } from 'react-i18next'
import { formatCurrency } from '@Helpers/number_helper'

const infos = [
	{icon: '💰', text: <Trans i18nKey="stepWelcome.infos.1" values={{amount: formatCurrency(1000)}} />},
	{icon: '⏱', text: <Trans i18nKey="stepWelcome.infos.2" />},
	{icon: '🌍', text: <Trans i18nKey="stepWelcome.infos.3" />},
	{icon: '🤝', text: <Trans i18nKey="stepWelcome.infos.4" />},
]

const BoxInfos = props => {

	return (
		<div className="welcome-box-info">
			<div className="title">⚡Easy tips⚡</div>
			<Row>
				{infos.map((i, k) => (
				<Col xs={6} key={k} className="box-info">
					<div className="icon">{i.icon}</div>
					<div className="text">{i.text}</div>
				</Col>
				))}
			</Row>
		</div>
	)

}

export default BoxInfos
