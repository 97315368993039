import React from 'react'
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags'
import Topbar from '@Components/Layout/Topbar'

const LayoutForm = props => {

	const { title, currentTab, children, handleSubmit } = props

	return (
		<React.Fragment>
			<MetaTags>
				<title>{title}</title>
			</MetaTags>
			<Topbar currentTab={currentTab}/>
			<div className="container container-with-sticky-topbar">
				<form onSubmit={handleSubmit} className="d-md-flex w-100">
					{children}
				</form>
			</div>
		</React.Fragment>
	)
}
LayoutForm.defaultProps = {
	title: 'Vote | Wiraki'
}

LayoutForm.propTypes = {
	children: PropTypes.any.isRequired,
	title: PropTypes.string,
	currentTab: PropTypes.string.isRequired,
	handleSubmit: PropTypes.func.isRequired,
}

export default LayoutForm
