import React, { useState } from 'react'
import ReactCardFlip from 'react-card-flip'
import routes from '@Routes/routeConfig'
import { useNavigate } from 'react-router-dom'
import { formatCurrency } from '@Helpers/number_helper'
import { Trans } from 'react-i18next'

const BoxVotesReceived = props => {

	const navigate = useNavigate()

	const [flipped, setFlipped] = useState(false)

	return (
		<ReactCardFlip containerClassName="w-100" isFlipped={flipped} flipDirection="horizontal">

			<div style={{ minHeight: '190px' }} className="mb-0 clickable box h-100 d-flex flex-column justify-content-evenly" onClick={() => setFlipped(!flipped)}>
				<div style={{ fontSize: '46px' }}>🗳</div>
				<div className="fw-bold"><Trans i18nKey="stepWelcome.flippingBoxes.boxVotesReceived.front" /></div>
			</div>

			<div style={{ minHeight: '190px' }} className="mb-0 clickable box flipped h-100 d-flex flex-column justify-content-evenly" onClick={() => setFlipped(!flipped)}>
				<div style={{ fontSize: '46px' }} className="align-self-start">🗳</div>
				<div className="fs-14"><Trans i18nKey="stepWelcome.flippingBoxes.boxVotesReceived.flipped" values={{amount: formatCurrency(75000)}} /></div>
				<div className="fs-14 clickable text-primary fw-bold mt-2" onClick={() => navigate(routes.UNIVERSITY)}><Trans i18nKey="stepWelcome.flippingBoxes.boxVotesReceived.cta" /></div>
			</div>

		</ReactCardFlip>
	)
}

export default BoxVotesReceived
