import React, { useEffect, useState } from 'react'
//Import Image
import { Card, CardBody, Col, Row } from 'reactstrap'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector, } from 'react-redux'
import { useForm, } from 'react-hook-form'
import axios from 'axios'
import { submitVotes } from '@Store/formVote/actions'
import Input from '@Atoms/Input'
import InputBox from '@Compounds/InputBox'
import LayoutForm from '@Components/Layout/LayoutForm'
import LayoutFormMobile from '@Components/Layout/LayoutFormMobile'
import LayoutFormDesktop from '@Components/Layout/LayoutFormDesktop'
import EasyTips from '@Compounds/EasyTips'
import user_deny from '@Assets/images/user_deny.svg'
import arrow_growth from '@Assets/images/arrow_growth.svg'
import lock from '@Assets/images/lock.svg'

const StepVotes = props => {

	const { t } = useTranslation()
	const currentTab = 'castVotes'

	const dataCastVotes = useSelector(state => state.FormVote.dataCastVotes)
	const referralData = useSelector(state => state.FormVote.referral)
	const dataCheckVote = useSelector(state => state.FormVote.dataCheckVote)

	const [submitError, setSubmitError] = useState()
	const [invitedToVote, setInvitedToVote] = useState(false)

	const dispatch = useDispatch()

	const tipsList = [
		{
			text: <Trans i18nKey="easyTips.1"/>, icon: <img src={lock} alt="" className="text-primary icon"/>
		},
		{
			text: <Trans i18nKey="easyTips.2"/>, icon: <img src={user_deny} alt="" className="text-primary icon"/>
		},
		{
			text: <Trans i18nKey="easyTips.3"/>, icon: <img src={arrow_growth} alt="" className="text-primary icon"/>
		},
	]

	const onSubmit = (data) => {
		setSubmitError(null)
		const string = `${data.firstVote},${data.secondVote},${data.thirdVote}`

		if (
			data.firstVote === referralData.fullName &&
			data.secondVote === referralData.fullName &&
			data.thirdVote === referralData.fullName
		) {
			setInvitedToVote(true)
		} else if (
			data.firstVote === referralData.fullName &&
			data.secondVote === '' &&
			data.thirdVote === ''
		) {
			setInvitedToVote(true)
		}

		axios.get(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/public/info/check-names-duplicates?names=${string}`)
			.then(() => {
				dispatch(submitVotes({ ...data, invitedToVote }))
			})
			.catch(() => {
				setSubmitError('You must vote for 3 different people')
			})
	}

	const {
		register,
		handleSubmit,
		formState: { errors },
		formState,
		setValue,
		watch
	} = useForm({
		mode: 'onBlur',
		reValidateMode: 'onChange',
	})

	useEffect(() => {
		setValue('firstVote', dataCastVotes.firstVote)
		setValue('secondVote', dataCastVotes.secondVote)
		setValue('thirdVote', dataCastVotes.thirdVote)
	}, [dataCastVotes])

	const buttonNextActive = !!watch('firstVote') && !!watch('secondVote') && !!watch('thirdVote')

	return (
		<LayoutForm
			currentTab={currentTab}
			handleSubmit={handleSubmit(onSubmit)}
		>
			<LayoutFormMobile
				stickyButtonLabel={t('stepVotes.button')}
				buttonNextActive={buttonNextActive}
			>
				<Card className="card-form">
					<CardBody className="p-3">
						<h4 className="my-2"><Trans i18nKey="stepVotes.title" values={{ faculty: dataCheckVote?.faculty?.name }}/></h4>
						<h6 className="mb-3"><Trans i18nKey="stepVotes.subtitle"/></h6>
						<div className="container-form">
							{['firstVote', 'secondVote', 'thirdVote'].map((input, k) => (
								<InputBox
									key={k}
									errors={errors[input]}
									touched={formState.touchedFields[input] || !!watch()[input]}
								>
									<Input
										required
										register={register}
										name={input}
										errors={errors[input]}
										touched={formState.touchedFields[input] || !!watch()[input]}
										placeholder={t('stepVotes.inputVote.placeholder')}
										inputErrorMessage={t('stepVotes.inputVote.error')}
										registerOptions={{ required: true, validate: async (value) => { return /^.+\s.+$/.test(value)} }}
										type="text"/>
								</InputBox>
							))}
							{submitError && (
								<div className="error-message">
									{submitError}
								</div>
							)}
						</div>
					</CardBody>
				</Card>
				<EasyTips tipsList={tipsList}/>
			</LayoutFormMobile>
			<LayoutFormDesktop
				currentTab={currentTab}
				buttonPreviousText={t('stepVotes.buttonBack')}
				buttonNextText={t('stepVotes.buttonNext')}
				buttonNextActive={buttonNextActive}
				title={(<h4 className="my-2"><Trans i18nKey="stepVotes.title" values={{ faculty: dataCheckVote?.faculty?.name }}/></h4>)}
			>
				<Row className="box-content-card h-100">
					<Col md={6} className="border-right">
						<div className="container-form">
							<div className="mb-4 fs-14">
								<div className="title text-center" style={{ fontSize: '22px', fontWeight: '600' }}><Trans i18nKey="stepVotes.desktopSubtitle"/></div>
								<Trans i18nKey="stepVotes.subtitle"/>
							</div>
							{['firstVote', 'secondVote', 'thirdVote'].map((input, k) => (
								<InputBox
									key={k}
									errors={errors[input]}
									touched={formState.touchedFields[input] || !!watch()[input]}
								>
									<Input
										required
										register={register}
										name={input}
										errors={errors[input]}
										touched={formState.touchedFields[input] || !!watch()[input]}
										placeholder={t('stepVotes.inputVote.placeholder')}
										inputErrorMessage={t('stepVotes.inputVote.error')}
										registerOptions={{ required: true, validate: async (value) => { return /^.+\s.+$/.test(value)} }}
										type="text"/>
								</InputBox>
							))}
							{submitError && (
								<div className="error-message">
									{submitError}
								</div>
							)}
						</div>
					</Col>
					<Col md={6}>
						<EasyTips tipsList={tipsList}/>
					</Col>
				</Row>
			</LayoutFormDesktop>
		</LayoutForm>
	)

}

export default StepVotes
