import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardBody } from 'reactstrap'
import FormSteps from '@Atoms/formSteps'
import { useNavigate } from 'react-router-dom'
import routes from '@Routes/routeConfig'
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded'
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded'
import classNames from 'classnames'

const LayoutFormDesktop = props => {

	const {
		children,
		footer,
		currentTab,
		title,
		buttonOnClick,
		buttonPreviousText,
		buttonNextText,
		buttonNextFilled,
		buttonNextWithoutIcon,
		buttonNextActive,
	} = props

	const navigate = useNavigate()

	let linkPreviousPage = null

	let previousPage = null
	Object.keys(routes).map(k => {
		if (routes[k] === window.location.pathname) {
			linkPreviousPage = previousPage
		}
		previousPage = routes[k]
	})

	if (window.innerWidth < 768) return null

	const nextButtonClasses = classNames({
		'btn-link btn-link-secondary': !buttonNextFilled,
		'btn-inactive': !buttonNextActive,
		'btn-rounded btn-secondary': buttonNextActive,
		'btn-with-icon-right btn btn-next fw-bolder px-4': true
	})


	return (
		<div className="d-none d-md-flex w-100 flex-column" id="layout-desktop">
			<Card className="main-card">
				<CardBody className="p-3 d-flex flex-column">
					<FormSteps
						className="menu w-75 mx-auto"
						currentTab={currentTab}
					/>
					<div className="title-container">
						{title}
					</div>
					{children}
					<div className="container-navigation d-flex justify-content-between">
						<button
							className="btn btn-link btn-back btn-with-icon-left px-4"
							type="button"
							onClick={() => navigate(linkPreviousPage)}
						>
							<NavigateBeforeRoundedIcon/>
							{buttonPreviousText}
						</button>
						<button
							className={nextButtonClasses}
							onClick={buttonOnClick}
							type={buttonOnClick ? 'button' : 'submit'}
						>
							{buttonNextText}
							{!buttonNextWithoutIcon && (
								<NavigateNextRoundedIcon/>
							)}
						</button>
					</div>
				</CardBody>
			</Card>
			{footer}
		</div>
	)
}
LayoutFormDesktop.defaultProps = {
	buttonNextFilled: false,
	buttonNextWithoutIcon: false,
	buttonNextActive: true,
}

LayoutFormDesktop.propTypes = {
	children: PropTypes.any.isRequired,
	currentTab: PropTypes.string.isRequired,
	title: PropTypes.any.isRequired,
	buttonNextText: PropTypes.string.isRequired,
	buttonPreviousText: PropTypes.string.isRequired,
	buttonOnClick: PropTypes.func,
	footer: PropTypes.any,
	buttonNextFilled: PropTypes.bool,
	buttonNextWithoutIcon: PropTypes.bool,
	buttonNextActive: PropTypes.bool,
}

export default LayoutFormDesktop
