import React, { useState } from 'react'
import jarett from '@Assets/images/talents/jarett_dewbury.jpeg'
import lucia from '@Assets/images/talents/lucia_testa.jpeg'
import timea from '@Assets/images/talents/timea_aszalos.jpeg'
import nikashan from '@Assets/images/talents/nikashan_thavaruban.jpeg'
import { Trans } from 'react-i18next'
import ReactCardFlip from 'react-card-flip'
import flags from '@Assets/images/flags.png'

const students = [jarett, lucia, nikashan, timea]

const BoxPeople = props => {

	const [flipped, setFlipped] = useState(false)

	return (
		<ReactCardFlip isFlipped={flipped} flipDirection="horizontal">
			<div className="clickable box box-people box-primary" onClick={() => setFlipped(!flipped)}>
				<div className="mb-4 container-students">
					{students.map((s, k) => (
						<div key={k} className="student">
							<img src={s} alt="" width={50}/>
						</div>
					))}
				</div>
				<div className="text">
					<Trans i18nKey="stepWelcome.desktop.slider.join"/>
				</div>
			</div>

			<div className="clickable box box-people flipped" onClick={() => setFlipped(!flipped)}>
				<div><Trans i18nKey="stepWelcome.flippingBoxes.boxPeople"/></div>
				<img src={flags} alt="" />
			</div>

		</ReactCardFlip>
	)
}

export default BoxPeople
