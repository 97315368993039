import React from 'react'
import Highlighter from 'react-highlight-words'

const highlightedStyle = {
	background: '#dedede',
	paddingRight: '2px',
	borderRight: '1px solid black'
}

export const selectAutocompleteStyle = showStyle => (
	{
		menuList: provided => ({
			...provided,
			padding: '4px'
		}),
		singleValue: base => {
			let obj = base
			if (showStyle) obj = {
				...obj,
				...highlightedStyle
			}
			return obj
		},
		option: (provided, state) => ({
				...provided,
				backgroundColor: state.isFocused ? '#DBD7D7' : provided.backgroundColor,
				borderRadius: state.isFocused ? '4px' : 0
			}
		)
	}
)

export const formatAutocomplete = (inputValue, label, logo) => {
	inputValue = inputValue.replaceAll(/[\[\]\(\)\{\}]+/g, '')
	return (<React.Fragment>
		<div className="d-flex align-items-center">
			{logo && (
				<img className="me-2 icon" alt="" width="20px" src={logo}/>
			)}
			<Highlighter
				searchWords={[inputValue]}
				textToHighlight={label}
				highlightClassName="highlight-result"
			/>
		</div>
	</React.Fragment>)
}
