// @flow
import { TOGGLE_MODAL, } from './actionTypes'

const INIT_STATE = {
	modal: {},
}

const Template = (state = INIT_STATE, action) => {
	switch (action.type) {
		case TOGGLE_MODAL:
			window.history.pushState(null, document.title, window.location.href)
			document.body.style.overflow = action.status ? 'hidden' : 'unset'

			const newState = {
				...state,
				modal: {
					...state.modal,
					[action.modal]: {
						...state.modal[action.modal],
						status: action.status,
					}
				}
			}
			// Update only if there are some data
			if (action.data) {
				newState.modal[action.modal].data = action.data
			}

			return newState
		default:
			return state
	}
}

export default Template
