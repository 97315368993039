import {
	CHECK_COUNTDOWN,
	CHECK_REFERRAL,
	LOAD_DATA_FACULTY,
	LOAD_DATA_UNIVERSITY,
	LOAD_GLOBAL_STATS,
	LOAD_LIST_PARTICIPANTS_FACULTY,
	LOAD_LIST_PARTICIPANTS_UNIVERSITY,
	LOAD_LOCAL_STORAGE_STATE,
	REDEEM_REWARD_SUBMITTED,
	REDIRECTED, SAVE_ATTENDING_YEAR,
	SAVE_FACULTY_DATA,
	SAVE_GLOBAL_STATS,
	SAVE_LIST_PARTICIPANTS_FACULTY,
	SAVE_LIST_PARTICIPANTS_UNIVERSITY,
	SAVE_UNIVERSITY_DATA,
	SET_REFERRAL_VALID,
	SUBMIT_REDEEM_REWARD,
	SUBMIT_UNIVERSITY,
	SUBMIT_VOTES,
} from './actionTypes'

export const loadLocalStorageState = () => ({
	type: LOAD_LOCAL_STORAGE_STATE,
	payload: null,
})

export const submitUniversity = (dataCheckVote) => ({
	type: SUBMIT_UNIVERSITY,
	payload: dataCheckVote,
})

export const submitVotes = (votes) => ({
	type: SUBMIT_VOTES,
	payload: votes,
})

export const submitRedeemReward = (votes) => ({
	type: SUBMIT_REDEEM_REWARD,
	payload: votes,
})
export const redeemRewardSubmitted = (obj) => ({
	type: REDEEM_REWARD_SUBMITTED,
	payload: obj
})

export const checkReferral = (token) => ({
	type: CHECK_REFERRAL,
	payload: token,
})
export const checkCountdown = () => ({
	type: CHECK_COUNTDOWN,
	payload: null
})

export const setTokenAsValid = (referral) => ({
	type: SET_REFERRAL_VALID,
	payload: referral,
})

export const saveUniversityData = (data) => ({
	type: SAVE_UNIVERSITY_DATA,
	payload: data,
})
export const saveFacultyData = (data) => ({
	type: SAVE_FACULTY_DATA,
	payload: data,
})

export const loadDataUniversity = (universityId) => ({
	type: LOAD_DATA_UNIVERSITY,
	payload: universityId,
})
export const loadDataFaculty = (facultyId) => ({
	type: LOAD_DATA_FACULTY,
	payload: facultyId,
})

export const loadListParticipantsUniversity = universityId => ({
	type: LOAD_LIST_PARTICIPANTS_UNIVERSITY,
	payload: universityId
})
export const saveListParticipantsUniversity = listParticipants => ({
	type: SAVE_LIST_PARTICIPANTS_UNIVERSITY,
	payload: listParticipants
})
export const loadListParticipantsFaculty = (universityId, facultyId) => ({
	type: LOAD_LIST_PARTICIPANTS_FACULTY,
	payload: { universityId, facultyId },
})
export const saveListParticipantsFaculty = listParticipants => ({
	type: SAVE_LIST_PARTICIPANTS_FACULTY,
	payload: listParticipants
})

export const loadGlobalStats = () => ({
	type: LOAD_GLOBAL_STATS,
	payload: null
})
export const saveGlobalStats = data => ({
	type: SAVE_GLOBAL_STATS,
	payload: data
})

export const redirected = () => ({
	type: REDIRECTED,
	payload: null
})

export const saveAttendingYear = (degreeType, attendingYear) => ({
	type: SAVE_ATTENDING_YEAR,
	payload: { degreeType, attendingYear}
})
