import React from 'react'
import Slider from '@Pages/StepWelcome/Desktop/Slider'
import HowItWorks from '@Pages/StepWelcome/Desktop/HowItWorks'
import EasyTips from '@Pages/StepWelcome/Desktop/EasyTips'
import Talents from '@Pages/StepWelcome/Desktop/Talents'
import TwoBoxes from '@Pages/StepWelcome/Desktop/TwoBoxes'
import Footer from '@Pages/StepWelcome/Desktop/Footer'

const Desktop = props => {

	return (
		<div id="step-welcome" className="d-none d-md-block">
			<Slider/>
			<HowItWorks />
			<EasyTips />
			<Talents />
			<TwoBoxes />
			<Footer />
		</div>
	)
}

export default Desktop
