import React from 'react'

const GradYears = (t, countryCode) => {

	const graduationYears = {
		IT: {
			bachelor: {
				label: t('stepRedeem.grades.bachelor'),
				value: 'bachelor',
				years: [
					{ label: t('stepRedeem.grades.1'), value: 'bachelor_1', obj: { degreeType: 'bachelor', attendingYear: 1 } },
					{ label: t('stepRedeem.grades.2'), value: 'bachelor_2', obj: { degreeType: 'bachelor', attendingYear: 2 } },
					{ label: t('stepRedeem.grades.3'), value: 'bachelor_3', obj: { degreeType: 'bachelor', attendingYear: 3 } },
					{ label: t('stepRedeem.grades.4'), value: 'bachelor_4', obj: { degreeType: 'bachelor', attendingYear: 4 } },
					{ label: t('stepRedeem.grades.5'), value: 'bachelor_5', obj: { degreeType: 'bachelor', attendingYear: 5 } },
				]
			},
			master: {
				label: t('stepRedeem.grades.master'),
				value: 'master',
				years: [
					{ label: t('stepRedeem.grades.1'), value: 'master_1', obj: { degreeType: 'master', attendingYear: 1 } },
					{ label: t('stepRedeem.grades.2'), value: 'master_2', obj: { degreeType: 'master', attendingYear: 2 } },
					{ label: t('stepRedeem.grades.3'), value: 'master_3', obj: { degreeType: 'master', attendingYear: 3 } },
				]
			},
			phd: {
				label: t('stepRedeem.grades.phd'),
				value: 'phd',
				years: [
					{ label: t('stepRedeem.grades.1'), value: 'phd_1', obj: { degreeType: 'phd', attendingYear: 1 } },
					{ label: t('stepRedeem.grades.2'), value: 'phd_2', obj: { degreeType: 'phd', attendingYear: 2 } },
					{ label: t('stepRedeem.grades.3'), value: 'phd_3', obj: { degreeType: 'phd', attendingYear: 3 } },
					{ label: t('stepRedeem.grades.4'), value: 'phd_4', obj: { degreeType: 'phd', attendingYear: 4 } },
					{ label: t('stepRedeem.grades.5'), value: 'phd_5', obj: { degreeType: 'phd', attendingYear: 5 } },
				]
			},
		},
		US: {
			associate: {
				label: t('stepRedeem.grades.associate'),
				value: 'associate',
				years: [
					{ label: t('stepRedeem.grades.1'), value: 'associate_1', obj: { degreeType: 'associate', attendingYear: 1 } },
					{ label: t('stepRedeem.grades.2'), value: 'associate_2', obj: { degreeType: 'associate', attendingYear: 2 } },
				]
			},
			bachelor: {
				label: t('stepRedeem.grades.bachelor'),
				value: 'bachelor',
				years: [
					{ label: t('stepRedeem.grades.1'), value: 'bachelor_1', obj: { degreeType: 'bachelor', attendingYear: 1 } },
					{ label: t('stepRedeem.grades.2'), value: 'bachelor_2', obj: { degreeType: 'bachelor', attendingYear: 2 } },
					{ label: t('stepRedeem.grades.3'), value: 'bachelor_3', obj: { degreeType: 'bachelor', attendingYear: 3 } },
					{ label: t('stepRedeem.grades.4'), value: 'bachelor_4', obj: { degreeType: 'bachelor', attendingYear: 4 } },
				]
			},
			master: {
				label: t('stepRedeem.grades.master'),
				value: 'master',
				years: [
					{ label: t('stepRedeem.grades.1'), value: 'master_1', obj: { degreeType: 'master', attendingYear: 1 } },
					{ label: t('stepRedeem.grades.2'), value: 'master_2', obj: { degreeType: 'master', attendingYear: 2 } },
				]
			},
			doctorate: {
				label: t('stepRedeem.grades.doctorate'),
				value: 'doctorate',
				years: [
					{ label: t('stepRedeem.grades.1'), value: 'doctorate_1', obj: { degreeType: 'doctorate', attendingYear: 1 } },
					{ label: t('stepRedeem.grades.2'), value: 'doctorate_2', obj: { degreeType: 'doctorate', attendingYear: 2 } },
					{ label: t('stepRedeem.grades.3'), value: 'doctorate_3', obj: { degreeType: 'doctorate', attendingYear: 3 } },
					{ label: t('stepRedeem.grades.4'), value: 'doctorate_4', obj: { degreeType: 'doctorate', attendingYear: 4 } },
				]
			},

		}
	}

	if (countryCode === 'US') return graduationYears.US
	return graduationYears.IT
}

export default GradYears
