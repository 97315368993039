import {ReactComponent as LogoIcon} from '@Assets/images/Logo.svg'
import PropTypes from 'prop-types'

const Logo = props => {

	return (
		<LogoIcon
			className={props.className}
			style={props.style}
			alt="" />
	)
}

Logo.propTypes = {
	style: PropTypes.object,
	className: PropTypes.string,
}

export default Logo
