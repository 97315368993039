import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadGlobalStats } from '@Store/formVote/actions'
import { Trans } from 'react-i18next'
import { toggleModal } from '@Store/template/actions'
import { MODAL_PARTICIPANTS } from '@Modals/index'
import ModalParticipants from '@Modals/ModalParticipants'

const sampleUsers = [
	'Carlo Martini',
	'Luca Mastrostefano',
	'Antonio Romano',
	'Valentina Ferretti',
	'Gerta Salillari',
	'Giada Loi',
]

const BoxInfoUniversity = props => {

	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(loadGlobalStats())
	}, [])

	const listParticipants = useSelector(state => state.FormVote.listParticipants)
	const dataCheckVote = useSelector(state => state.FormVote.dataCheckVote)
	const globalStats = useSelector(state => state.FormVote.globalStats)

	const university = dataCheckVote?.university

	const renderBeforeSelectUniversity = () => (
		<div className="list-students blurred">
			<div className="blurred-box">
				<Trans i18nKey="stepUniversity.boxInfoUniversity.emptyTitle"/>
			</div>
			{sampleUsers.map((u, k) => (
				<div className="student blurred" key={k}>{u}</div>
			))}
		</div>
	)

	const renderWithListParticipants = () => (
		<>
			<div className="stats-students">
				<div className="container-logo">
					<img src={university?.logo} alt="university-logo"/>
					<span>{university?.historyVotes}</span>
				</div>
				<div className="rounded-bar"/>
				<span><Trans i18nKey="stepUniversity.boxInfoUniversity.votesCast"/></span>
			</div>
			<div className="list-students">
				{listParticipants.university.slice(0, 9).map((u, k) => (
					<div className="student" key={k}>{u}</div>
				))}
				<div className="discover-list">
					{/*<NavigateNextRoundedIcon className="icon"/>*/}
				</div>
			</div>
		</>
	)

	const renderWithoutListParticipants = () => (
		<div className="no-participants">
			<strong className="d-none d-md-block">
				<span className="icon-globe">🌍</span>
				<span className="text-padded">{globalStats?.users * 3}</span>
				<span className="icon-globe">🌍</span>
				<div className="rounded-bar"/>
			</strong>
			<span className="d-md-none">{globalStats?.users}&nbsp;🌍</span>
			<span><Trans i18nKey="stepUniversity.boxInfoUniversity.votesCast"/></span>
		</div>
	)

	const render = () => {
		if (!university) return renderBeforeSelectUniversity()
		if (listParticipants.university && listParticipants.university.length > 0) return renderWithListParticipants()
		return renderWithoutListParticipants()
	}

	if (!listParticipants.loaded) return null

	return (
		<div className="container-students clickable" onClick={() => dispatch(toggleModal(MODAL_PARTICIPANTS, true))}>
			{render()}
			<ModalParticipants/>
		</div>
	)
}

export default BoxInfoUniversity
