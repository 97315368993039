import React from 'react'
import { Button } from 'reactstrap'
import PropTypes from 'prop-types'

const StickyButton = props => {

	return (
		<div className={props.className}>
			<div className="sticky-button-spacer"/>
			<div className="sticky-button-submit">
				<div className="blur"/>
				<Button
					disabled={props.disabled}
					className={`btn-rounded fw-bold btn-${props.buttonColor} ${props.buttonClassName}`}
					onClick={props.onClick}
					type={props.onClick ? 'button' : 'submit'}
				>
					{props.buttonText}
				</Button>
			</div>
		</div>
	)
}

StickyButton.defaultProps = {
	buttonColor: 'primary',
	disabled: false
}

StickyButton.propTypes = {
	buttonText: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	buttonColor: PropTypes.oneOf(['primary', 'secondary']),
	disabled: PropTypes.bool,
	className: PropTypes.string,
	buttonClassName: PropTypes.string,
}

export default StickyButton
