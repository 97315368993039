import React, { useEffect, useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { Trans } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Logo from '@Assets/images/Logo.png'
import underline from '@Assets/images/underline.svg'
import Countdown from '@Atoms/countdown'
import { toggleModal } from '@Store/template/actions'
import { MODAL_REFERRAL } from '@Modals/index'

const ModalReferral = () => {

	const dispatch = useDispatch()

	const [isOpen, setOpen] = useState(false)

	const referral = useSelector(state => state.FormVote.referral)
	const countdown = useSelector(state => state.FormVote.countdown)

	const dataModal = useSelector(state => state.Template.modal)

	useEffect(() => {
		setOpen(!!dataModal[MODAL_REFERRAL]?.status)
	}, [dataModal])

	useEffect(() => {
		if (referral && referral.fullName && referral.fullName !== '' && !isOpen) {
			dispatch(toggleModal(MODAL_REFERRAL, true))
		}
	}, [referral])

	return (
		<React.Fragment>
			<Modal
				centered
				size="md"
				isOpen={isOpen}
				toggle={() => dispatch(toggleModal(MODAL_REFERRAL, false))}
				className="m-auto"
				contentClassName="border-0"
				id="modal-referral"
			>
				<ModalBody className="p-5 text-center">
					<div className="">
						<img src={Logo} style={{ width: '46px' }} alt=""/>
						<h3 className="modal-title">
							<Trans i18nKey="modalReferral.title" values={{ name: referral.fullName }}/>
						</h3>
						<img src={underline} alt=""/>
						<p className="modal-text">
							<Trans i18nKey="modalReferral.text" components={{ span: <span style={{ fontWeight: 600 }}/> }}/>
						</p>
					</div>
					<Countdown date={countdown} className="text-white mb-4"/>
					<button
						onClick={() => dispatch(toggleModal(MODAL_REFERRAL, false))}
						type="button"
						className="btn btn-rounded btn-lg btn-primary"
						data-dismiss="modal"
					>
						<Trans i18nKey="modalReferral.button"/>
					</button>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}
export default ModalReferral
