import React, { useEffect, useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { Trans } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { MODAL_PARTICIPANTS } from '@Modals/index'
import { toggleModal } from '@Store/template/actions'

const sampleUsers = [
	'Carlo Martini',
	'Luca Mastrostefano',
	'Antonio Romano',
	'Valentina Ferretti',
	'Gerta Salillari',
	'Giada Loi',
]

const ModalParticipants = () => {

	const dispatch = useDispatch()

	const [isOpen, setOpen] = useState(false)

	const listParticipants = useSelector(state => state.FormVote.listParticipants)
	const dataModal = useSelector(state => state.Template.modal)

	useEffect(() => {
		setOpen(!!dataModal[MODAL_PARTICIPANTS]?.status)
	}, [dataModal])


	return (
		<React.Fragment>
			<Modal
				centered
				size="md"
				isOpen={isOpen}
				toggle={() => dispatch(toggleModal(MODAL_PARTICIPANTS, false))}
				className="m-auto"
				contentClassName="border-0"
				id="modal-participants"
			>
				<ModalBody className="text-center">
					<div className="container-students flex-column">
						<h3 className="modal-title">
							<Trans i18nKey="modalParticipants.title"/>
						</h3>
						{listParticipants.university && listParticipants.university.length > 0 && (
						<div className="list-students flex-row">
							{listParticipants.university.slice(0, 9).map((u, k) => (
								<div className="student w-45" key={k}>{u}</div>
							))}
							<div className="discover-list"></div>
						</div>
						)}
						<p className="modal-text">
							<Trans i18nKey="modalParticipants.text"/>
						</p>
					</div>
					<button
						onClick={() => dispatch(toggleModal(MODAL_PARTICIPANTS, false))}
						type="button"
						className="btn btn-link-secondary btn-lg"
						data-dismiss="modal"
					>
						<Trans i18nKey="modalParticipants.button"/>
					</button>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}
export default ModalParticipants
