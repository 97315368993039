import React from 'react'
import { Trans } from 'react-i18next'
import { Col, Row } from 'reactstrap'

const EasyTips = props => {

	const { tipsList } = props

	return (
		<div id="easy-tips">
			<div className="title">
				<span><Trans i18nKey="easyTips.title"/></span>
			</div>
			{/* Mobile */}
			<div className="d-flex flex-column py-1 d-md-none">
				{tipsList.map((tip, k) => (
				<Row key={k} className="element flex-row">
					<Col xs={2} className="text-center">{tip.icon}</Col>
					<Col xs={10} className="text text-start">{tip.text}</Col>
				</Row>
				))}
			</div>
			{/* /Mobile */}
			{/* Desktop */}
			<div className="pb-5">
				{tipsList.map((tip, k) => (
					<Row key={k} className="d-none d-md-flex easy-tips-container">
						<Col md={3} className="icon-carded my-2 justify-content-end">
							<div className="p-2">
								{tip.icon}
							</div>
						</Col>
						<Col md={9} className="container-text">
							<div className="text">{tip.text}</div>
						</Col>
					</Row>
				))}
			</div>
			{/* /Desktop */}
		</div>
	)

}

export default EasyTips
