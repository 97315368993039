export default [
	'https://assets.wiraki.com/img/companies/fiscozen.png',
	'https://assets.wiraki.com/img/companies/leaf-space.png',
	'https://assets.wiraki.com/img/companies/anothereality.png',
	'https://assets.wiraki.com/img/companies/contents.png',
	'https://assets.wiraki.com/img/companies/edgemony_logo.png',
	'https://assets.wiraki.com/img/companies/fluentify.png',
	'https://assets.wiraki.com/img/companies/treedom.png',
	'https://assets.wiraki.com/img/companies/techbricks_logo.jpg',
	'https://assets.wiraki.com/img/companies/coverzen.png',
	'https://assets.wiraki.com/img/companies/skienda.png',
	'https://assets.wiraki.com/img/companies/techedge.png',
	'https://assets.wiraki.com/img/companies/filo.png',
	'https://assets.wiraki.com/img/companies/rebrandly.png',
	'https://assets.wiraki.com/img/companies/be.png',
	'https://assets.wiraki.com/img/companies/shippypro.png',
	'https://assets.wiraki.com/img/companies/translated.png',
]
