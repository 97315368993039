import { isEmpty, isPlainObject, keyBy, mergeWith } from 'lodash'

export function updateParam (obj) {
	const localStorageState = JSON.parse(localStorage.getItem('formState'))

	const res = keepUnchangedRefsOnly(localStorageState, obj)

	localStorage.setItem('formState', JSON.stringify(res))
}

function keepUnchangedRefsOnly (objValue, srcValue) {
	if (objValue === undefined) { // do i need this?
		return srcValue
	} else if (srcValue === undefined) { // do i need this?
		return objValue
	} else if (isPlainObject(objValue)) {
		return mergeWith({}, objValue, srcValue, keepUnchangedRefsOnly)
	} else if (Array.isArray(objValue)) {
		if (isEmpty(objValue) && !isEmpty(srcValue)) return [...srcValue]
		else if (!isEmpty(objValue) && isEmpty(srcValue)) return objValue
		else if (isEmpty(objValue) && isEmpty(srcValue)) return objValue // both empty
		else {
			// if array is array of objects, then assume each object has id, and merge based on id
			// so create new array, based objValue. id should match in each spot

			if (isPlainObject(objValue[0]) && objValue[0].hasOwnProperty('id')) {
				const srcCollection = keyBy(srcValue, 'id')

				const aligned = objValue.map(el => {
					const { id } = el
					if (srcCollection.hasOwnProperty(id)) {
						const srcEl = srcCollection[id]
						delete srcCollection[id]
						return mergeWith({}, el, srcEl, keepUnchangedRefsOnly)
					} else {
						return el
					}
				})

				aligned.push(...Object.values(srcCollection))

				return aligned
			} else {
				return [...objValue, ...srcValue]
			}
		}
	}
}

export function scrollToElement (e, offset = 40) {
	const positionY = e.target.getBoundingClientRect().top + +document.documentElement.scrollTop
	window.scrollTo(0, positionY - offset)
}
