import React from 'react'
import { Card, Col, Row } from 'reactstrap'
import CarouselCompanies from '@Compounds/CarouselCompanies'
import { Trans } from 'react-i18next'
import CarouselUniversities from '@Compounds/CarouselUniversities'
import routes from '@Routes/routeConfig'
import { useNavigate } from 'react-router-dom'

const TwoBoxes = props => {

	const navigate = useNavigate()

	return (
		<div className="container py-5">
			<Row style={{ marginTop: '150px' }}>
				<Col md={6} className="two-box-container container-text pe-5">
					<div className="icon-carded">
						<Card className="p-2">🏫</Card>
					</div>
					<h4><Trans i18nKey="stepWelcome.desktop.twoBoxes.university"/></h4>
					<p><Trans i18nKey="stepWelcome.desktop.twoBoxes.universityText"/></p>
				</Col>
				<Col md={6} className="two-box-container container-carousel">
					<div className="carousel-card">
						<CarouselUniversities />
					</div>
				</Col>
			</Row>
			<Row style={{ marginTop: '100px' }}>
				<Col md={6} className="two-box-container container-carousel">
					<div className="carousel-card">
						<CarouselCompanies/>
					</div>
				</Col>
				<Col md={6} className="two-box-container container-text ps-5">
					<div className="icon-carded">
						<Card className="p-2">💼</Card>
					</div>
					<h4><Trans i18nKey="stepWelcome.desktop.twoBoxes.companies"/></h4>
					<p><Trans i18nKey="stepWelcome.desktop.twoBoxes.companiesText"/></p>
				</Col>
			</Row>
			<div style={{marginTop: '100px', marginBottom: '150px'}} className="text-center">
				<button
					className="btn btn-lg btn-primary btn-rounded fw-bold"
					onClick={() => navigate(routes.UNIVERSITY)}
				>
					<Trans i18nKey="stepWelcome.desktop.twoBoxes.button" />
				</button>
			</div>
		</div>
	)
}

export default TwoBoxes
