import { ERROR_AXIOS_CALL, ERROR_AXIOS_CALL_400, ERROR_AXIOS_CALL_500 } from '@Store/system/actionTypes'

const INIT_STATE = {}

const Global = (state = INIT_STATE, action) => {
	switch (action.type) {
		case ERROR_AXIOS_CALL:
			return {
				...state
			}
		case ERROR_AXIOS_CALL_400:
			return {
				...state
			}
		case ERROR_AXIOS_CALL_500:
			return {
				...state
			}
		default:
			return state
	}
}

export default Global
