import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import ReactCardFlip from 'react-card-flip'
import sparks from '@Assets/images/sparks.png'
import { Col, Row } from 'reactstrap'






const BoxSteps = props => {

	const elements = [
		{ step: 1, title: <Trans i18nKey="stepWelcome.flippingBoxes.boxSteps.1.title"/>, text: <Trans i18nKey="stepWelcome.flippingBoxes.boxSteps.1.text"/> },
		{ step: 2, title: <Trans i18nKey="stepWelcome.flippingBoxes.boxSteps.2.title"/>, text: <Trans i18nKey="stepWelcome.flippingBoxes.boxSteps.2.text"/> },
		{ step: 3, title: <Trans i18nKey="stepWelcome.flippingBoxes.boxSteps.3.title"/>, text: <Trans i18nKey="stepWelcome.flippingBoxes.boxSteps.3.text"/> },
	]

	const [flipped, setFlipped] = useState(false)

	return (
		<ReactCardFlip isFlipped={flipped} flipDirection="horizontal">
			<div className="clickable box box-steps" onClick={() => setFlipped(!flipped)}>
				<div className="text"><Trans i18nKey="stepWelcome.desktop.slider.steps"/></div>
				<img src={sparks} alt="" width="120px"/>
			</div>

			<div className="clickable box box-steps flipped position-relative" onClick={() => setFlipped(!flipped)}>
				{elements.map((e, k) => (
					<React.Fragment key={k}>
						<div className="progress-bar bg-secondary"/>
						<Row className="w-100">
							{e.step !== 3 ? (
								<Col xs={1} className="text-center px-0" style={{ zIndex: '100' }}>
									<div className="fw-bold text-secondary" style={{ backgroundColor: '#fff' }}>{e.step}</div>
								</Col>
							) : (
								<Col xs={1} className="text-center px-0" style={{ backgroundColor: '#fff' }}>
									<div className="fw-bold text-secondary" style={{ backgroundColor: '#fff' }}>{e.step}</div>
								</Col>
							)}
							<Col xs={11}>
								<div className="fw-bold text-secondary ">{e.title}</div>
								<div className="fw-light fs-12 ps-4">{e.text}</div>
							</Col>
						</Row>
					</React.Fragment>
				))}
			</div>
		</ReactCardFlip>
	)
}

export default BoxSteps
