import React from 'react'
import 'react-drawer/lib/react-drawer.css'
import FormSteps from '@Atoms/formSteps'
import { useDispatch, useSelector } from 'react-redux'
import Countdown from '@Atoms/countdown'
import { Trans } from 'react-i18next'
import { Col, Row } from 'reactstrap'
import AccessAlarmRoundedIcon from '@mui/icons-material/AccessAlarmRounded'
import Logo from '@Icons/Logo'
import { Link, useNavigate } from 'react-router-dom'
import routes from '@Routes/routeConfig'
import { toggleModal } from '@Store/template/actions'
import { MODAL_COUNTDOWN } from '@Modals/index'
import ModalCountdown from '@Modals/ModalCountdown'

const Topbar = props => {

	const { currentTab } = props

	const dispatch = useDispatch()

	const dataCheckVote = useSelector(state => state.FormVote.dataCheckVote)

	const universityLogo = dataCheckVote.university?.logo

	const navigate = useNavigate()

	// Show logo only on castVotes and redeemReward steps
	const showLogo = ['checkVote', 'castVotes', 'redeemReward'].indexOf(currentTab) !== -1

	return (
		<div className={`topbar ${currentTab === 'welcome' ? 'topbar-secondary' : ''}`}>
			<div className="topbar-stick">
				<div className="topbar-container h-100">
					<Row className="h-100 w-100 d-flex align-items-center">
						<Col xs={6} md={5}>
							<Link to="/">
								<div className="logo-container">
									<Logo/>
									{universityLogo && showLogo && (
										<div className="logo-university">
											<img alt="" src={universityLogo}/>
										</div>
									)}
									<div className="logo-name">Wiraki</div>
								</div>
							</Link>
						</Col>
						<Col xs={6} className="text-end d-md-none clickable" onClick={() => { dispatch(toggleModal(MODAL_COUNTDOWN, true)) }}>
							<Countdown
								compact
								icon={<AccessAlarmRoundedIcon/>}
								className="text-white clickable"
							/>
						</Col>
						<Col md={2} className="text-md-center d-none d-md-block px-0 clickable" onClick={() => { dispatch(toggleModal(MODAL_COUNTDOWN, true)) }}>
							<Countdown
								compact
								desktop
								className="text-white countdown-desktop clickable"
							/>
						</Col>
						<Col md={5} className="d-none d-md-block text-end container-already-voted">
							<Trans
								i18nKey="stepWelcome.alreadyVoted"
								components={{ a: <a className="fw-bold" href={process.env.REACT_APP_USER_AREA_URL}/>, icon: <></> }}
							/>
							{currentTab === 'welcome' && (
								<button
									className="btn btn-rounded btn-outline btn-primary d-none d-lg-inline-block"
									onClick={() => navigate(routes.UNIVERSITY)}
								>
									<Trans i18nKey="stepWelcome.desktop.slider.button"/>
								</button>
							)}
						</Col>
						<FormSteps
							className="d-md-none menu-topbar"
							currentTab={currentTab}
						/>
					</Row>
				</div>
			</div>
			<ModalCountdown/>
		</div>
	)
}

export default Topbar
