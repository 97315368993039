import React from 'react'
import PropTypes from 'prop-types'
import ModalStepBlocked from '@Modals/ModalStepBlocked'

const NonAuthLayout = props => {
	return (
		<React.Fragment>
			<div className="main-content">
				{props.children}
			</div>
			<ModalStepBlocked />
		</React.Fragment>
	)
}

NonAuthLayout.propTypes = {
	children: PropTypes.any,
	location: PropTypes.object,
}

export default NonAuthLayout
